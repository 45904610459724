import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import React, { useState, useEffect } from 'react'
import {
  Descriptions, Tabs, Spin, Card, Divider, Tag, Row, Col, Drawer
} from 'antd'
import 'react-quill/dist/quill.snow.css'
import { getAllOrderDispatchAction } from '../actions'
import moment from 'moment'
import { DispatchFilesLogs, StatusLogs, ProductsLogs, DateLogs } from '../../complaint/view/logs'
import { getStatusColor } from '../../../request'

const { TabPane } = Tabs


const OrderDetails = (props) => {
  const [orderData, setOrderData] = useState()
  const [id, setId] = useState()
  const [loader, setLoader] = useState(false)
  const [activeKey, setActiveKey] = useState('0')
  const [statusLogs, setStatusLogs] = useState([])
  const [dateObj, setDateData] = useState({})


  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('id')
    setId(id)
  }, [])

  useEffect(
    () => {
      if (id) {
        setLoader(true)
        getAllOrderDispatchAction({ _id: id })
          .then(({ data }) => {
            if (data && data.length) {
              let orderData = data[0]
              setOrderData(orderData)
              setStatusLogs(orderData.statusLogs)
              setDateData({
                orderAcceptedDate: orderData && orderData.orderAcceptedDate,
                orderPreparingDate: orderData && orderData.orderPreparingDate,
                orderPreparedDate: orderData && orderData.orderPreparedDate,
                qualityCheckDate: orderData && orderData.qualityCheckDate,
                qualityApprovedDate: orderData && orderData.qualityApprovedDate,
                readyToDispatchedDate: orderData && orderData.readyToDispatchedDate,
                dispatched: orderData && orderData.dispatched
              })

            }
            setLoader(false)
          })
          .catch(error => {
            setLoader(false)

            console.log(error)
          })
      } else {
        setLoader(false)
      }
    },
    [id]
  )


  return (<Spin size="large" spinning={loader}>
    <PageHeaderWrapper title={'Order Details'}>

      <Card>
        <Row>
          <Col>
            <Descriptions column={4}>
              <Descriptions.Item label={<b>Order Id</b>}>{orderData && orderData.id}</Descriptions.Item>
              <Descriptions.Item
                label={<b>Order
                  Date</b>}>{orderData && moment(orderData.orderDate).format('ll')}</Descriptions.Item>
              <Descriptions.Item
                label={<b>Status</b>}><Tag
                color={getStatusColor(orderData && orderData.status)}>{orderData && orderData.status}</Tag></Descriptions.Item>
              <Descriptions.Item label={<b>Lead Name</b>}>{orderData && orderData.leadName}</Descriptions.Item>
              <Descriptions.Item
                label={<b>Invoice Number</b>}>{orderData && orderData.invoiceNumber}</Descriptions.Item>
              <Descriptions.Item label={<b>GST</b>}>{orderData && orderData.gstNumber}</Descriptions.Item>
              <Descriptions.Item label={<b>Carrier</b>}>{orderData && orderData.carrier}</Descriptions.Item>

              {orderData && orderData.estimatedDate ?
                <Descriptions.Item
                  label={<b>Estimated Date</b>}>{moment(orderData.estimatedDate).format('ll')}</Descriptions.Item>
                : ''}
              {orderData && orderData.orderName ?
                <Descriptions.Item
                  label={<b>Dispatch Number</b>}>{orderData.orderName}</Descriptions.Item>
                : ''}
            </Descriptions>


          </Col>
        </Row>
        <Divider />

        <Row>

          <Col>
            <h2 style={{ backgroundColor: '#8080800f' }}>Contact Details</h2>

            <Descriptions>
              <Descriptions.Item
                label={<b>Contact Name</b>}>{orderData && orderData.contactName}</Descriptions.Item>
              <Descriptions.Item
                label={<b>Mobile</b>}>{orderData && orderData.mobile}</Descriptions.Item>
              <Descriptions.Item label={<b>Email</b>}>{orderData && orderData.email}</Descriptions.Item>
              <Descriptions.Item label={<b>State</b>}>{orderData && orderData.state}</Descriptions.Item>
              <Descriptions.Item label={<b>Pincode</b>}>{orderData && orderData.pincode}</Descriptions.Item>
            </Descriptions>


          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={24}>
            <Card style={{ marginTop: '30px' }}>
              <Tabs
                type="line"
                activeKey={activeKey}
                defaultActiveKey={'0'}
                onTabClick={(key) => {
                  setActiveKey(key)
                }}
              >
                <TabPane tab={'Products'} key={0}>

                  <ProductsLogs setProductIds={orderData && orderData.setProductIds}
                                productsData={orderData && orderData.products} orderId={orderData && orderData._id} />


                </TabPane>
                <TabPane tab={'Status Logs'} key={1}>
                  <StatusLogs allStatusLogs={statusLogs} />


                </TabPane>
                <TabPane tab={'Video'} key={2}>
                  <DispatchFilesLogs videos={orderData && orderData.videos}  _id={id}/>

                </TabPane>
                <TabPane tab={'Photos'} key={3}>
                  <DispatchFilesLogs photos={orderData && orderData.photos} _id={id} />

                </TabPane>
                <TabPane tab={'Date Logs'} key={4}>
                  <DateLogs orderDate={dateObj} />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>


      </Card>


    </PageHeaderWrapper>

  </Spin>)
}
export default OrderDetails




