import React, { useState, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils4'
import {
  Button,
  Card,
  Drawer,
  Popconfirm,
  Tooltip,
  Tag,
  notification,
  Modal,
  Form,
  DatePicker,
  Select,
  Upload,
  message
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import {
  getAllOrderDispatchAction,
  updateOrderDispatchStatusAction
} from '../actions'
import {
  CheckOutlined,
  ProjectOutlined,
  EyeOutlined,
  FileImageOutlined,
  FileDoneOutlined,
  UploadOutlined,
  DownCircleOutlined,
  DownloadOutlined
} from '@ant-design/icons'

import {
  API_URL,
  carriers,
  orderStatus,
  getStatusColor
} from '../../../request'
import {
  AcceptedListAction,
  PreparingListAction,
  UpdateDocketNumber
} from '../../complaint/view/logs'

import Request from '../../../request'

const DispatchList = () => {
  const tableRef = useRef()
  const [count, setCount] = useState(0)
  const [loader, setLoader] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [orderId, setOrderId] = useState(null)
  const [key, setKey] = useState()
  const [isPhoto, setIsPhoto] = useState(false)
  const [isStatusChange, setIsStatusChange] = useState(false)
  const [isVideo, setIsVideo] = useState(false)
  const [currentStatus, setCurrentStatus] = useState(null)
  const [arrayLoader, setArrayLoader] = useState([])
  const [dispatchLoader, setDispatchLoader] = useState(false)
  // const [ids,setIds] = useState([]);

  const apiRequest = params => {


    return new Promise(async resolve => {
      if (!params.status) {
        let searchParams = new URLSearchParams(window.location.search)
        let statusType = searchParams.get('type')
        if (statusType) {

          params.status = statusType
        }
      }
       
      if (!params.sortField) {
        params.sortField = 'createdAt'
        params.sortOrder = 'descend'
      }


      let data = await getAllOrderDispatchAction({
        ...params,
        regExFilters: [
          'mobile',
          'name',
          'email',
          'status',
          'leadName',
          'invoiceNumber',
          'remarks',
          'technicianName'
        ]
      })
      // if(data && data.total){
        setCount(data.total)
      // }
      resolve(data)
    })
  }
  const updateOrderStatus = async data => {
    if (!data._id) {
      data._id = orderId
    }

    //  setLoader(true)
    let x = await updateOrderDispatchStatusAction(data)
    // setLoader(false)

    tableRef.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`
      })
      handleCancel()
    } else {
      notification.error({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`
      })
    }
  }

  let checkFirstWarranty = (products) => {

    return (!!products.some(product=> {
      if(product?.productWarranty?.length>0){
        return true
      }
    }))

   
    
  }
  

  const setLoaderForAction = (key, value) => {
    let newLoading = _.clone(arrayLoader)
    newLoading[key] = value
    setArrayLoader(newLoading)
  }

  const myColumns = [
    {
      title: 'Lead Name',
      key: 'leadName',
      width: 200,
      fixed: 'left',
      searchTextName: 'lead'
    },
    {
      title: 'Order Date',
      dataIndex: 'orderDate',
      key: 'orderDate',
      width: 180,
      //  searchDateName: 'orderDate',
      render: (text, record) => {
        return <Tag>{text ? moment(text).format('lll') : ''}</Tag>
      },
      sorter: true
    },
    {
      title: 'Contact Details',
      key: 'contactDetails',
      searchTextName: 'contactDetails',
      render: (text, record) => (
        <span>
          <span>{record.contactName || ''}</span>
          <br />
          <span>{record.mobile || ''}</span>
          <br />
          <span>{record.email || ''}</span>
          <br />
          <span>{record.state || ''}</span>
          <br />
        </span>
      ),
      width: 200
    },
    {
      title: 'Products',
      dataIndex: 'products',
      key: 'products',
      width: 200,
      render: (val, record, index) => {
        return (
          <React.Fragment>
            <div>
              <ul style={{ paddingLeft: '0px', listStyle: 'none' }}>
                {val &&
                  val.length &&
                  val.map((item, key) => {
                    return <li key={key}>{item.productName}</li>
                  })}
              </ul>
            </div>
          </React.Fragment>
        )
      }
    },
    {
      title: 'carrier',
      dataIndex: 'carrier',
      width: 100,
      key: 'carrier',
      filters: carriers && carriers.map(x => ({ value: x, text: x }))
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 180,
      key: 'status',

      filters: orderStatus && orderStatus.map(x => ({ value: x, text: x })),
      render: val => {
        return <Tag color={getStatusColor(val)}>{val}</Tag>
      }
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoiceNumber',
      width: 150,
      key: 'invoiceNumber',
      searchTextName: 'invoiceNumber'
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      width: 150,
      key: 'remarks',
      searchTextName: 'remarks'
    },
    {
      title: 'Technician Name',
      dataIndex: 'technicianName',
      width: 150,
      key: 'technicianName',
      searchTextName: 'technicianName'
    },
    {
      title: 'Docket Number',
      dataIndex: 'dispatchNumber',
      width: 150,
      key: 'dispatchNumber',
      searchTextName: 'dispatchNumber'
    },
    {
      title: 'Estimated Date',
      dataIndex: 'estimatedDate',
      key: 'estimatedDate',
      width: 180,
      //   searchDateName: 'estimatedDate',
      render: (text, record) => {
        return <Tag>{text ? moment(text).format('lll') : ''}</Tag>
      },
      sorter: true
    },

    {
      title: 'Order Accepted Date',
      dataIndex: 'orderAcceptedDate',
      key: 'orderAcceptedDate',
      width: 180,
      // searchDateName: 'orderAcceptedDate',
      render: (text, record) => {
        return <Tag>{text ? moment(text).format('lll') : ''}</Tag>
      },
      sorter: true
    },
    {
      title: 'Order Preparing Date',
      dataIndex: 'orderPreparingDate',
      key: 'orderPreparingDate',
      width: 180,
      // searchDateName: 'orderPreparingDate',
      render: (text, record) => {
        return <Tag>{text ? moment(text).format('lll') : ''}</Tag>
      },
      sorter: true
    },
    {
      title: 'Order Prepared Date',
      dataIndex: 'orderPreparedDate',
      key: 'orderPreparedDate',
      width: 180,
      // searchDateName: 'orderPreparedDate',
      render: (text, record) => {
        return <Tag>{text ? moment(text).format('lll') : ''}</Tag>
      },
      sorter: true
    },
    {
      title: 'Quality Check Date',
      dataIndex: 'qualityCheckDate',
      key: 'qualityCheckDate',
      width: 180,
      //searchDateName: 'qualityCheckDate',
      render: (text, record) => {
        return <Tag>{text ? moment(text).format('lll') : ''}</Tag>
      },
      sorter: true
    },
    {
      title: 'Quality Approved Date',
      dataIndex: 'qualityApprovedDate',
      key: 'qualityApprovedDate',
      width: 180,
      // searchDateName: 'qualityApprovedDate',
      render: (text, record) => {
        return <Tag>{text ? moment(text).format('lll') : ''}</Tag>
      },
      sorter: true
    },
    {
      title: 'Ready To Dispatched Date',
      dataIndex: 'readyToDispatchedDate',
      key: 'readyToDispatchedDate',
      width: 180,
      //searchDateName: 'readyToDispatchedDate',
      render: (text, record) => {
        return <Tag>{text ? moment(text).format('lll') : ''}</Tag>
      },
      sorter: true
    },
    {
      title: 'Dispatched Date',
      dataIndex: 'dispatched',
      key: 'dispatched',
      width: 180,
      // searchDateName: 'dispatched',
      render: (text, record) => {
        return <Tag>{text ? moment(text).format('lll') : ''}</Tag>
      },
      sorter: true
    },

    {
      key: 'actions',
      title: 'Actions',
      fixed: 'right',
      width: 100,
      render: (val, record, key) => {
        
        return (
          <React.Fragment>
            {actionAccordingStatus(record.status, record, key)}

            <Tooltip title={'Order Details'}>
              <a
                href={`/orderDispatch/orderDetails?id=${record && record._id}`}
                target={'_blank'}>
                <Button size="small" icon={<EyeOutlined />} shape={'circle'} />
              </a>
            </Tooltip>

            {
              
              <>
                {
                  record?.products && checkFirstWarranty(record?.products) && (
                    <Tooltip title={'Download Warranty Report'} >
                      <Button size="small" shape={'circle'} style={{marginLeft:5}} onClick={()=>{downloadDispatch(record._id)}} icon={<DownloadOutlined/>} />

                    </Tooltip>
                  )
                }
              </>
              
            }


          </React.Fragment>
        )
      }
    }
  ]

  const handleChange = record => {
    setOrderId(record._id)
    setKey(Date.now())
    setCurrentStatus(record && record.status)
  }
  
  const downloadDispatch = async (_id) => {

    
    let date = moment().format('lll');
   
    let x=await Request.downloadWarranty(_id);
    if(!x.error){
     return  notification.success({message:"PDF Generated Successfully"});
    }else{
      return notification.error({message:"Error Occurred"});
    }
    
  }

  const actionAccordingStatus = (status, record, key) => {
    switch (status) {
      case 'Order Received':
        return (
          <Popconfirm
            title="Are you sure to accepted this Order?"
            onConfirm={async () => {
              setLoaderForAction(key, true)
              await updateOrderStatus({
                _id: record._id,
                status: 'Order Accepted'
              })
              setLoaderForAction(key, false)
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No">
            <Tooltip title="Accept Order">
              <Button
                shape="circle"
                loading={arrayLoader[key]}
                type={'primary'}
                style={{ marginRight: 6, marginBottom: 6 }}
                size="small"
                onClick={() => {}}
                icon={<CheckOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        )

      case 'Order Accepted':
        return (
          <>
            <Tooltip title="Preparing Order">
              <Button
                shape="circle"
                loading={loader}
                type={'primary'}
                style={{ marginRight: 6, marginBottom: 6 }}
                size="small"
                onClick={() => {
                  handleChange(record)
                  setModalVisible(true)
                }}
                icon={<CheckOutlined />}
              />
            </Tooltip>
          </>
        )
      case 'Order Preparing':
        return (
          <>
            {' '}
            <Tooltip title="Upload Photo">
              <Button
                shape="circle"
                loading={loader}
                style={{ marginRight: 6, marginBottom: 6 }}
                size="small"
                onClick={() => {
                  handleChange(record)
                  setModalVisible(true)
                  setIsPhoto(true)
                }}
                icon={<FileImageOutlined />}
              />
            </Tooltip>
            <Tooltip title="Upload Video">
              <Button
                shape="circle"
                loading={loader}
                style={{ marginRight: 5, marginBottom: 6 }}
                size="small"
                onClick={() => {
                  handleChange(record)
                  setModalVisible(true)
                  setIsVideo(true)
                }}
                icon={<FileDoneOutlined />}
              />
            </Tooltip>
            {(record.photos.length > 0 || record.videos.length > 0) && (
              <Tooltip title="Change Status">
                <Button
                  shape="circle"
                  loading={loader}
                  type={'primary'}
                  style={{ marginBottom: 6 }}
                  size="small"
                  onClick={() => {
                    handleChange(record)
                    setModalVisible(true)
                    setIsStatusChange(true)
                  }}
                  icon={<CheckOutlined />}
                />
              </Tooltip>
            )}
          </>
        )

      case 'Order Prepared':
        return (
          <Popconfirm
            title="Are you sure to send this Order for Quality Check?"
            onConfirm={async () => {
              setLoaderForAction(key, true)

              await updateOrderStatus({
                _id: record._id,
                status: 'Quality Check'
              })

              setLoaderForAction(key, false)
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No">
            <Tooltip title="Order for Quality Check">
              <Button
                shape="circle"
                loading={arrayLoader[key]}
                type={'primary'}
                style={{ marginRight: 6, marginBottom: 6 }}
                size="small"
                onClick={() => {}}
                icon={<CheckOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        )

      case 'Quality Approved':
        return (
          <Popconfirm
            title="Are you sure for ready to dispatch?"
            onConfirm={async () => {
              setLoaderForAction(key, true)

              await updateOrderStatus({
                _id: record._id,
                status: 'Ready To Dispatched'
              })
              setLoaderForAction(key, false)
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No">
            <Tooltip title="Ready To Dispatched">
              <Button
                shape="circle"
                loading={arrayLoader[key]}
                type={'primary'}
                style={{ marginRight: 6, marginBottom: 6 }}
                size="small"
                onClick={() => {}}
                icon={<EyeOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        )
      case 'Ready To Dispatched':
        return (
          <Popconfirm
            title="Are you sure to Dispatched this order?"
            onConfirm={async () => {
              setLoaderForAction(key, true)

              await updateOrderStatus({ _id: record._id, status: 'Dispatched' })
              setLoaderForAction(key, false)
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No">
            <Tooltip title="Dispatch Order">
              <Button
                shape="circle"
                loading={arrayLoader[key]}
                type={'primary'}
                style={{ marginRight: 6, marginBottom: 6 }}
                size="small"
                onClick={() => {}}
                icon={<CheckOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        )

      case 'Dispatched':
        return (
          <Tooltip title="Update Docket Number">
            <Button
              shape="circle"
              size="small"
              loading={arrayLoader[key]}
              type={'primary'}
              style={{ marginRight: '6px' }}
              onClick={async () => {
                handleChange(record)
                setModalVisible(true)
              }}
              icon={<CheckOutlined />}
            />
          </Tooltip>
        )
    }
  }
  const handleCancel = () => {
    setModalVisible(false)
    setIsVideo(false)
    setIsPhoto(false)
    setIsStatusChange(false)
  }

  return (
    <>
      <PageHeaderWrapper title={`Dispatch Order List (${count || 0})`}>
        <Card bordered={true}>
          <TableComp
            ref={tableRef}
            columns={myColumns}
            extraProps={{ scroll: { x: 1300 } }}
            apiRequest={apiRequest}
            pagination={{
              defaultPageSize: 10,
              pageSizeOptions: ['10', '25', '50', '100'],
              showSizeChanger: true
            }}
          />

          <Modal
            width={isPhoto ? 800 : 500}
            key={key}
            confirmLoading={loader}
            visible={modalVisible}
            footer={false}
            onCancel={handleCancel}>
            {currentStatus === 'Order Accepted' && (
              <AcceptedListAction
                orderId={orderId}
                onCancel={handleCancel}
                handleSubmit={() => {
                  handleCancel()
                  tableRef.current.reload()
                }}
              />
            )}
            {currentStatus === 'Order Preparing' && (
              <PreparingListAction
                orderId={orderId}
                isVideo={isVideo}
                isPhoto={isPhoto}
                isStatusChange={isStatusChange}
                onCancel={handleCancel}
                handleSubmit={() => {
                  handleCancel()
                  tableRef.current.reload()
                }}
              />
            )}
            {currentStatus === 'Dispatched' && (
              <UpdateDocketNumber
                orderId={orderId}
                onCancel={handleCancel}
                handleSubmit={() => {
                  handleCancel()
                  tableRef.current.reload()
                }}
              />
            )}
          </Modal>
        </Card>
      </PageHeaderWrapper>
    </>
  )
}

export default DispatchList
