import { notification } from 'antd'
import axios from 'axios'
import { getToken } from '../../../request'
import { getAllComplaintsUrl, postCommentUrl, postStatusUrl, putUploadFileUrl } from '../api/index'

export const getAllComplaintsAction = async (params) => {
  try {
    let { data } = await axios.get(getAllComplaintsUrl(), { params: { ...params }, ...getToken() })
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const postCommentAction = async (params) => {
  try{
   const data = await axios.post(
       postCommentUrl(), params ,
       getToken()
   )
   return data
  }catch(err){
      notification.error({
          message: 'Error'
        })
        return null
  }
}
export const postStatusAction = async (params) => {
  try{
   const data = await axios.post(
       postStatusUrl(), params ,
       getToken()
   )
   return data
  }catch(err){
      notification.error({
          message: 'Error'
        })
        return null
  }
}
export const putUploadFileAction = async (params) => {
  try{
   const data = await axios.put(
       putUploadFileUrl(params._id), params,
       getToken()
   )
   return data
  }catch(err){
      notification.error({
          message: 'Error'
        })
        return null
  }
}