import React, { Component, useEffect, useState } from 'react'
import Request from '../../request'
import { Row, Col, Card } from 'antd'

import { getUrlPushWrapper } from '../../routes'
import { useDispatch } from 'react-redux'

const DashboardStats = props => {
  const [total, setTotal] = useState(null)
  const [userType, setUserType] = useState(null)
  useEffect(async () => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {

      setUserType(user.userType)
    }
    if (user.userType === 'Dispatch') {
      const { data, error } = await Request.getOrderDispatchCount('hhhg')
      if (!error) {
        setTotal(data[0])
      }
    }
    if (user.userType === 'Technician') {
      const { data, error } = await Request.getComplaintCount('hhhg')
      if (!error) {
        setTotal(data[0])
      }
    }
  }, [])
  let dispatch = useDispatch()

  return (
    <>
      {userType === 'Dispatch' ? (
        <Card title={<h3>Order Count</h3>}>
          <Row gutter={16}>
            <Col span={2}/>
            <Col span={4} style={{ justifyContent: 'space-around' }}>
              <a
                style={{
                  backgroundColor: '#095d51',
                  color: 'white',
                  borderRadius: 10,
                  padding: 20,
                  display: 'block',
                  margin: 10
                }}
                onClick={() => {
                  dispatch(
                    getUrlPushWrapper('orderDispatch', {
                      type: 'Order Received'
                    })
                  )
                }}>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18
                  }}>
                  Order
                  <br/>
                  Received
                </div>
                <br/>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 20
                  }}>
                  {total !== null ? total.orderReceived : 0}
                </div>
              </a>
            </Col>
            <Col span={4} style={{ justifyContent: 'space-around' }}>
              <a
                style={{
                  backgroundColor: '#095d51',
                  color: 'white',
                  borderRadius: 10,
                  padding: 20,
                  display: 'block',
                  margin: 10
                }}
                onClick={() => {
                  dispatch(
                    getUrlPushWrapper('orderDispatch', {
                      type: 'Order Accepted'
                    })
                  )
                }}>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18
                  }}>
                  Order Accepted
                </div>
                <br/>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 20
                  }}>
                  {total !== null ? total.orderAccepted : 0}
                </div>
              </a>
            </Col>
            <Col span={4} style={{ justifyContent: 'space-around' }}>
              <a
                style={{
                  backgroundColor: '#095d51',
                  color: 'white',
                  borderRadius: 10,
                  padding: 20,
                  display: 'block',
                  margin: 10
                }}
                onClick={() => {
                  dispatch(
                    getUrlPushWrapper('orderDispatch', {
                      type: 'Order Preparing'
                    })
                  )
                }}>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18
                  }}>
                  Order Preparing
                </div>
                <br/>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 20
                  }}>
                  {total !== null ? total.orderPreparing : 0}
                </div>
              </a>
            </Col>
            <Col span={4} style={{ justifyContent: 'space-around' }}>
              <a
                style={{
                  backgroundColor: '#095d51',
                  color: 'white',
                  borderRadius: 10,
                  padding: 20,
                  display: 'block',
                  margin: 10
                }}
                onClick={() => {
                  dispatch(
                    getUrlPushWrapper('orderDispatch', {
                      type: 'Order Prepared'
                    })
                  )
                }}>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18
                  }}>
                  Order Prepared
                </div>
                <br/>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 20
                  }}>
                  {total !== null ? total.orderPrepared : 0}
                </div>
              </a>
            </Col>
            <Col span={4} style={{ justifyContent: 'space-around' }}>
              <a
                style={{
                  backgroundColor: '#095d51',
                  color: 'white',
                  borderRadius: 10,
                  padding: 20,
                  display: 'block',
                  margin: 10
                }}
                onClick={() => {
                  dispatch(
                    getUrlPushWrapper('orderDispatch', {
                      type: 'Quality Check'
                    })
                  )
                }}>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18
                  }}>
                  Quality
                  <br/> Check
                </div>
                <br/>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 20
                  }}>
                  {total !== null ? total.qualityCheck : 0}
                </div>
              </a>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 16 }}>
            <Col span={4}/>
            <Col span={4} style={{ justifyContent: 'space-around' }}>
              <a
                style={{
                  backgroundColor: '#095d51',
                  color: 'white',
                  borderRadius: 10,
                  padding: 20,
                  display: 'block',
                  margin: 10
                }}
                onClick={() => {
                  dispatch(
                    getUrlPushWrapper('orderDispatch', {
                      type: 'Quality Approved'
                    })
                  )
                }}>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18
                  }}>
                  Quality Approved
                </div>
                <br/>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 20
                  }}>
                  {total !== null ? total.qualityApproved : 0}
                </div>
              </a>
            </Col>
            <Col span={4} style={{ justifyContent: 'space-around' }}>
              <a
                style={{
                  backgroundColor: '#095d51',
                  color: 'white',
                  borderRadius: 10,
                  padding: 20,
                  display: 'block',
                  margin: 10
                }}
                onClick={() => {
                  dispatch(
                    getUrlPushWrapper('orderDispatch', {
                      type: 'Ready To Dispatched'
                    })
                  )
                }}>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18
                  }}>
                  Ready To Dispatched
                </div>
                <br/>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 20
                  }}>
                  {total !== null ? total.readyToDispatched : 0}
                </div>
              </a>
            </Col>
            <Col span={4} style={{ justifyContent: 'space-around' }}>
              <a
                style={{
                  backgroundColor: '#095d51',
                  color: 'white',
                  borderRadius: 10,
                  padding: 20,
                  display: 'block',
                  margin: 10
                }}
                onClick={() => {
                  dispatch(
                    getUrlPushWrapper('orderDispatch', {
                      type: 'Dispatched'
                    })
                  )
                }}>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18
                  }}>
                  Order Dispatched
                </div>
                <br/>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 20
                  }}>
                  {total !== null ? total.dispatched : 0}
                </div>
              </a>
            </Col>
          </Row>
        </Card>
      ) : (
        <Card title={<h3>Complaint Count</h3>}>
          <Row gutter={24}>
         
            <Col span={4} style={{ justifyContent: 'space-around' }}>
              <a
                style={{
                  backgroundColor: '#095d51',
                  color: 'white',
                  borderRadius: 10,
                  padding: 35,

                  display: 'block',
                  margin: 10
                }}
                onClick={() => {
                  dispatch(getUrlPushWrapper('complaints.open'))
                }}>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18
                  }}>
                  Open
                </div>
                <br/>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 20
                  }}>
                  {total !== null ? total.open : 0}
                </div>
              </a>
            </Col>
            <Col span={4} style={{ justifyContent: 'space-around' }}>
              <a
                style={{
                  backgroundColor: '#095d51',
                  color: 'white',
                  borderRadius: 10,
                  padding: 20,
                  display: 'block',
                  margin: 10
                }}
                onClick={() => {
                  dispatch(getUrlPushWrapper('complaints.fixed'))
                }}>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18
                  }}>
                  Fixed By Technician
                </div>
                <br/>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 20
                  }}>
                  {total !== null ? total.fixedByTech : 0}
                </div>
              </a>
            </Col>
            <Col span={4} style={{ justifyContent: 'space-around' }}>
              <a
                style={{
                  backgroundColor: '#095d51',
                  color: 'white',
                  borderRadius: 10,
                  paddingTop: 8,
                  paddingBottom:10,
                  display: 'block',
                  margin: 10
                }}
                onClick={() => {
                  dispatch(getUrlPushWrapper('complaints.complaintToHO'))
                }}
                >
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18
                  }}>
                 Complaint<br/>
                 To  Head 
                 <br/>
                 Office
                </div>
                <br/>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 20
                  }}>
                  {total !== null ? total.complaintToHeadOffice : 0}
                </div>
              </a>
            </Col>
            <Col span={4} style={{ justifyContent: 'space-around' }}>
              <a
                style={{
                  backgroundColor: '#095d51',
                  color: 'white',
                  borderRadius: 10,
                  padding: 23,
                  display: 'block',
                  margin: 10
                }}
                onClick={() => {
                  dispatch(getUrlPushWrapper('complaints.transferToASC'))
                }}
                >
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18
                  }}>
                  Transfer
                  <br/>
                To ASC
                </div>
                <br/>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 20
                  }}>
                  {total !== null ? total.transferToAsc : 0}
                </div>
              </a>
            </Col>
            <Col span={4} style={{ justifyContent: 'space-around' }}>
              <a
                style={{
                  backgroundColor: '#095d51',
                  color: 'white',
                  borderRadius: 10,
                  padding: 35,
                  display: 'block',
                  margin: 10
                }}
                onClick={() => {
                  dispatch(getUrlPushWrapper('complaints.close'))
                }}>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18
                  }}>
                  Close
                </div>
                <br/>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 20
                  }}>
                  {total !== null ? total.close : 0}
                </div>
              </a>
            </Col>
          </Row>
        </Card>
      )}
    </>
  )
}

export default DashboardStats
