import React, { useEffect, useRef, useState } from 'react'
import {
  Row,
  Avatar,
  Col,
  Card,
  Table,
  Tag,
  List,
  Modal,
  Descriptions,
  Select,
  Timeline,
  Upload,
  Form,
  DatePicker,
  Tooltip,
  Popconfirm,
  Button,
  Input,
  notification,
  Dropdown,
  Menu
  
} from 'antd'
import 'react-quill/dist/quill.snow.css'
import _ from 'lodash'
import { API_URL, truncate } from '../../../request'
import Request from '../../../request'

import {
  DownloadOutlined,
  UploadOutlined,
  DeleteOutlined,
  PlusOutlined,
  EditOutlined
} from '@ant-design/icons'
import Style from './style.less'
import moment from 'moment'
import {
  updateOrderDispatchStatusAction,
  updateOrderAction,
  deletePhotosAndVideos
} from '../../dispatch/actions'
import { updateOrderUrl } from '../../dispatch/api'

const { Option } = Select
export const ComplaintActivityModal = props => {
  const { isModelLogsVisible, cancelActivityModal } = props
  return (
    <Modal
      visible={isModelLogsVisible}
      title="Complaint Details"
      onCancel={cancelActivityModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}>
      <div>{props.commentKey.message}</div>
    </Modal>
  )
}

export const CommentLogs = props => {
  const [commentKey, setCommentKey] = useState('')
  const [isModelLogsVisible, setIsModelLogsVisible] = useState(false)

  const cancelActivityModal = () => {
    setIsModelLogsVisible(false)
  }

  return (
    <div>
      <Timeline mode={'left'} style={{ padding: '20px' }}>
        {props.allComments.map((val, key) => {
          return (
            <>
              <Timeline.Item key={key}>
                <a
                  className={Style.textBlack}
                  onClick={() => {
                    setCommentKey(val)
                    setIsModelLogsVisible(true)
                  }}>
                  {' '}
                  {truncate(val.message, 24)} by <b>{val.user}</b> on{' '}
                  <b>{moment(val.time).format('lll')}</b>
                </a>
              </Timeline.Item>
            </>
          )
        })}
      </Timeline>

      {isModelLogsVisible &&
        ComplaintActivityModal({
          isModelLogsVisible,
          commentKey,
          cancelActivityModal
        })}
    </div>
  )
}
export const StatusLogs = props => {
  return (
    <div>
      <Timeline mode={'left'} style={{ padding: '20px' }}>
        {props.allStatusLogs.map((val, key) => {
          return (
            <>
              <Timeline.Item key={key}>
                <a className={Style.textBlack}>
                  {' '}
                  {val.message} by <b>{val.user}</b> on{' '}
                  <b>{moment(val.time).format('lll')}</b>
                </a>
              </Timeline.Item>
            </>
          )
        })}
      </Timeline>
    </div>
  )
}
export const FilesLogs = props => {
  return (
    <div>
      <Timeline mode={'left'} style={{ padding: '20px' }}>
        <Card>
          <List
            dataSource={props.imageArray}
            renderItem={(item, key) => (
              <List.Item key={key}>
                <List.Item.Meta
                  avatar={<Avatar src={`${API_URL}/${item}`} />}
                  title={
                    <a
                      onClick={() => {
                        window.open(`${API_URL}/${item}`, 'download')
                      }}
                      target={'_blank'}>
                      Download
                    </a>
                  }
                  description={''}
                />
              </List.Item>
            )}
          />
        </Card>
      </Timeline>
    </div>
  )
}

export const DispatchFilesLogs = props => {
  let { videos, photos } = props

  const handleDelete = async val => {
    if (props._id) {
      val.orderId = props._id

      let { error } = await deletePhotosAndVideos(val)
      if (!error) {
        notification.success({ message: 'Deleted Successfully' })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
        let photoN = _.reject(props.photo, item => {
          return (val._id = item._id)
        })
        // photos = photoN
      }
    }
  }

  const columns3 = [
    {
      title: 'Download Photos',
      key: 'url',
      render: (val, record) => {
        if (val) {
          return (
            <div
              style={{ display: 'flex', 'justify-content': 'space-between' }}>
              <img
                src={`${API_URL}${record && record.url}`}
                style={{ width: '50px', height: '50px' }}
              />
              <a
                onClick={() => {
                  window.open(`${API_URL}/${record && record.url}`, 'download')
                }}>
                <Tag>
                  {' '}
                  <DownloadOutlined /> Download
                </Tag>
              </a>
              <Tooltip title="Delete Photo">
                <Popconfirm
                  title="Are you sure delete this Photo?"
                  onConfirm={() => {
                    record.type = 'photos'
                    handleDelete(record)
                  }}
                  onCancel={() => {
                    console.log()
                  }}
                  okText="Yes"
                  cancelText="No">
                  <Button shape="circle" size="small" type={'danger'}>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Tooltip>
            </div>
          )
        } else {
          return
        }
      }
    }
  ]
  const videoColumn = [
    {
      title: 'Download Videos',
      key: 'url',
      render: (val, record) => {
        if (val) {
          return (
            <div
              style={{ display: 'flex', 'justify-content': 'space-between' }}>
              <iframe
                src={`${API_URL}${record && record.url}`}
                style={{ width: '50px', height: '50px' }}
              />
              <a
                onClick={() => {
                  window.open(`${API_URL}/${record && record.url}`, 'download')
                }}>
                <Tag>
                  {' '}
                  <DownloadOutlined /> Download
                </Tag>
              </a>
              <Tooltip title="Delete Video">
                <Popconfirm
                  title="Are you sure delete this Video?"
                  onConfirm={() => {
                    record.type = 'videos'
                    handleDelete(record)
                  }}
                  onCancel={() => {
                    console.log()
                  }}
                  okText="Yes"
                  cancelText="No">
                  <Button shape="circle" size="small" type={'danger'}>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Tooltip>
            </div>
          )
        } else {
          return
        }
      }
    }
  ]

  return (
    <React.Fragment>
      <div>
        <Row gutter={8}>
          {videos && videos.length > 0 && (
            <Col span={12}>
              <Table
                size={'small'}
                columns={videoColumn}
                dataSource={videos}
                pagination={false}
              />
            </Col>
          )}

          <Col span={12}>
            {photos && photos.length > 0 && (
              <Table
                size={'small'}
                columns={columns3}
                dataSource={photos}
                pagination={false}
              />
            )}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export const ProductsLogs = props => {
  let { productsData, orderId, setProductIds } = props
  const [loader, setLoader] = useState(false)
  const [key, setKey] = useState()
  const [modalVisible, setModalVisible] = useState(false)
  const [totalData, setTotalData] = useState([])
  const [productId, setProductId] = useState([])
  const [productWarranty, setProductWarranty] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [prodIdEdit, setProdIdEdit] = useState()
  const [editedProductId, setEditedProductId] = useState('')

  const [formData, setFormData] = useState([])
  const [id, setId] = useState()
  const [form] = Form.useForm()
  const [form2] = Form.useForm()
  const [selectedProductId, setSelectedProductId] = useState('');
  

  const handleInputChange = (e) => {
    console.log(e.target.value,"hhhhhhuuu");
    setEditedProductId(e.target.value);
    console.log("vvvvvvvvvv",editedProductId);
  };



  const onFinish2 = () => {
    console.log("finish edit value",editedProductId);
    console.log("selected value",selectedProductId);
    console.log("to be edited in ",prodIdEdit);

    const data = {
        editedProductId: editedProductId,
        selectedProductId: selectedProductId,
        _id: prodIdEdit._id
      };

    Request.EditProductId(data).then((data)=>{
        notification.success({ message: data.message })

    }).catch((error)=>{
        console.log(error);
        notification.success({ message: data.message })
    })
    setTimeout(()=>{
      window.location.reload()

    },2000)    


  }

  const onFinish = values => {
    form.resetFields()
    console.log(typeof values, 'value', values, 'Here is id', id)
    const newData = [values, ...formData]
    setFormData(newData)
    setProductWarranty([values, ...productWarranty])
  }

  const handleDropdownChanges = (value) => {
    console.log("usestate",selectedProductId);
    
    
    setSelectedProductId(value);
    form2.setFieldsValue({"editedValue":value})
    console.log(selectedProductId);
  };

  const onCancel = () => {
    setModalVisible(false)
    setFormData([])
    form.resetFields()
  }

  const onCancelEdit = () => {
    setProdIdEdit([])
    setIsEdit(false)
    form2.resetFields();
  }
  const handleDelete = val => {
    if (val._id) {

      Request.deleteProductWarranty(val._id)
        .then(data => {
          notification.success({ message: data.message })
          console.log(data, 'check data in use')
          return
        })
        .catch(error => {
          console.error('Error getting product warranty:', error)
        })
    }

    let tt = productWarranty
    tt = _.reject(tt, val)
    setProductWarranty(tt)
  }


  useEffect(()=>{
    form.resetFields();

  },[isEdit]);

  useEffect(
    () => {
      // let searchParams = new URLSearchParams(window.location.search)
      // let id = searchParams.get('id')
      Request.getProductWarranty(id)
        .then(data => {
          if (!data.error) {
            setProductWarranty(data.data)
          }
          console.log(data.data, 'check data in use')
        })
        .catch(error => {
          console.error('Error getting product warranty:', error)
        })
    },
    [modalVisible]
  )

  const updatePage = async () => {
    
    // let searchParams = new URLSearchParams(window.location.search)
    // let id = searchParams.get('id')
    let data = await Request.updateProductWarranty(id, formData)
    if (!data.error) {
      notification.success({ message: 'Warranty Updated Successfully ' })
    } else {
      console.log('Hiiiii')
      notification.error({ message: 'Error Occured' })
    }

    setFormData([])
    setModalVisible(false)
    //   window.location.reload();
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 12 }
    }
  }

  const centeredTextStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const refColumns = [
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      width: 200
    },
    {
      title: 'Photo',
      dataIndex: 'photos',
      width: 100,
      key: 'photos',
      render: (val, item) => {
        if (item && item.newPhotosArray && item.newPhotosArray.length) {
          return _.map(item.newPhotosArray, ii => {
            return (
              <div>
                <img
                  src={`${API_URL}${ii}`}
                  style={{ width: '50%', height: '50px', marginBottom: '10px' }}
                />
              </div>
            )
          })
        } else {
          return (
            <div>
              {val && (
                <img
                  src={`${API_URL}${val}`}
                  style={{ width: '50%', height: '50px' }}
                />
              )}
            </div>
          )
        }
      }
    },

    // {
    //   title: 'ProductIds',
    //   key: 'productIds',
    //   width: 200,
    //   dataIndex: 'productIds',
    //   render: (x, item) => {
    //     return <div>{item && item.productIds.join(' , ')}</div>
    //   }
    //
    //
    // },

    {
      title: 'ProductIds',
      key: 'productIds',
      width: 200,
      dataIndex: 'productIds',
      render: (x, item, key) => {
        return (
          <div>
            <Select
              defaultValue={item && item.productIds}
              disabled={item && item.productIds.length}
              // mode={'multiple'}
              style={{ width: 200 }}
              placeholder={'Enter Product Id'}
              mode={'tags'}
              onChange={e => {
                let array = []
                _.each(e, i => {
                  array.push(i.toLowerCase())
                })
                item.productIds = array
              }}
            />

            <Button
              shape="none"
              style={{ marginRight: 6, padding: 0, border: 'none', background: 'none' }}
              onClick={() => {
                console.log('huuu',productId);
                console.log('Hiiiiiiiiii', item);
                setIsEdit(true)
                setProdIdEdit(item);
                console.log(prodIdEdit);
              }}>
              {' '}
              <EditOutlined />{' '}
            </Button>
          </div>
        )
      }
    },

    {
      title: 'Quantity',
      key: 'quantity',
      width: 100,
      dataIndex: 'quantity',
      render: (text, record) => {
        return (
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <span>{record.quantity}</span>
              <Tooltip title="Add Warranty">
                <Button
                  shape="circle"
                  style={{ marginRight: 6 }}
                  onClick={() => {
                    setId(record._id)
                    console.log(record, 'inside modal')
                    setProductId(record.productIds)
                    setModalVisible(true)
                  }}>
                  <PlusOutlined />
                </Button>
              </Tooltip>
            </div>
          </React.Fragment>
        )
      }
    }
  ]

  const refColumns1 = [
    {
      title: 'ProductIds',
      key: 'productId',
      dataIndex: 'productId'
    },
    {
      title: 'Part Name',
      key: 'partName',
      dataIndex: 'partName'
    },
    {
      title: 'Serial Number',
      key: 'serialNumber',
      dataIndex: 'serialNumber'
    },
    {
      title: 'Warranty',
      key: 'warranty',
      dataIndex: 'warranty'
    },
    {
      title: 'Action',
      key: 'operation',
      width: 80,
      render: (item, record) => {
        if (record && record.isLast != true) {
          return (
            <React.Fragment>
              <Tooltip title="Delete Product">
                <Popconfirm
                  title="Are you sure delete this Product?"
                  onConfirm={() => {
                    handleDelete(record)
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No">
                  <Button shape="circle" size="small" type={'danger'}>
                    {' '}
                    <DeleteOutlined />{' '}
                  </Button>
                </Popconfirm>
              </Tooltip>
            </React.Fragment>
          )
        }
      }
    }
  ]

  return (
    <div>
      <div style={{ textAlign: 'right', marginBottom: '10px' }}>
        <Button
          hidden={key ? key : setProductIds}
          loading={loader}
          type={'primary'}
          onClick={async () => {
            let foundFlag = false

            let tempArr = []
            let errorIds = []
            _.each(productsData, item => {
              if (item.productIds && item.productIds.length != item.quantity) {
                foundFlag = true
              }
              _.each(item.productIds, val => {
                if (!tempArr.includes(val)) {
                  tempArr.push(val)
                } else {
                  errorIds.push(val)
                }
              })
            })

            if (errorIds.length) {
              return notification.error({
                message: `Duplicate Product Ids ${errorIds}.`
              })
            }
            if (foundFlag) {
              return notification.error({
                message: 'Product Ids must be equivalent to quantity.'
              })
            }
            let data = {
              _id: orderId,
              products: productsData,
              setProductIds: true
            }
            setLoader(true)
            let x = await updateOrderAction(data)
            setLoader(false)

            if (!x.error) {
              setKey(x && x.data && x.data.setProductIds)

              notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
              })
            } else {
              notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
              })
            }
          }}>
          Add Product Ids
        </Button>
      </div>

      <Table
        size={'small'}
        columns={refColumns}
        dataSource={productsData}
        pagination={false}
      />

      <Modal
        title={`Add Warranty (${productWarranty.length})  `}
        visible={modalVisible}
        onCancel={onCancel}
        width={1200}
        maskClosable={false}
        footer={false}>
        <div>
          <Row>
            <Form form={form} onFinish={onFinish}>
              <Row gutter={8}>
                <Col>
                  <Form.Item
                    label="ProductId"
                    name="productId"
                    rules={[
                      { required: true, message: 'Please select a ProductId' }
                    ]}>
                    <Select placeholder={'productIds'} style={{ width: 180 }}>
                      {productId &&
                        productId.map(val => (
                          <Option key={val} value={val}>
                            <>
                              <div>
                                <b>{`${val}`}</b>
                              </div>
                            </>
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Part Name"
                    name="partName"
                    rules={[
                      { required: true, message: 'Please enter part name' }
                    ]}>
                    <Input style={{ width: 120 }} />
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item
                    label="Serial Number"
                    name="serialNumber"
                    rules={[
                      { required: true, message: 'Please enter serial number' }
                    ]}>
                    <Input style={{ width: 120 }} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Warranty(months)"
                    name="warranty"
                    rules={[
                      { required: true, message: 'Please enter a Warranty' },
                      {
                        validator: (_, value) => {
                          const regex = /^[0-9]+$/
                          if (!value || regex.test(value)) {
                            return Promise.resolve()
                          }
                          return Promise.reject('Please enter a valid number.')
                        }
                      }
                    ]}>
                    <Input style={{ width: 120 }} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    wrapperCol={{ ...formItemLayout.wrapperCol, offset: 8 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: 50, ...centeredTextStyle }}>
                      Add
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Row>
        </div>

        <div style={{ marginTop: '30px' }}>
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <Table
              bordered={true}
              columns={refColumns1}
              pagination={false}
              dataSource={productWarranty}
            />
          </div>
          <row
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <Button
              type="primary"
              style={{ color: 'white', margin: '20px' }}
              onClick={updatePage}>
              SAVE
            </Button>
          </row>
        </div>
      </Modal>

      <Modal
        title={`Edit ProductIds`}
        visible={isEdit}
        onCancel={onCancelEdit}
        width={500}
        height={500}
        maskClosable={false}
        footer={false} centered>


    <Form form={form2} onFinish={onFinish2} >
      <Form.Item name="selectedValue" label="Previous ProductID" {...formItemLayout}>
      <Select
            style={{ width: 200 }}
            placeholder="Select Product ID"
            // value={selectedProductId}
            onSelect={handleDropdownChanges}
         >
            {prodIdEdit && prodIdEdit.productIds && prodIdEdit.productIds.map((id, index) =>{
                return <Option key={index} value={id}>
                    <>
                        <div>
                            {`${id}`}
                        </div>
                    </>
                </Option>
            })}
        </Select>
      </Form.Item>
    {form2.getFieldValue('selectedValue') && (
      <>
        <Form.Item name="editedValue" label="New ProductID" {...formItemLayout}>
          <Input
          style={{ width: 200 }}
          value={selectedProductId}
          onChange={handleInputChange}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" >
            Save
          </Button>
      </Form.Item>
      </>
    )}
    </Form>
          
      </Modal>
    </div>
  )
}

export const DateLogs = props => {
  const { orderDate } = props

  return (
    <React.Fragment>
      <div>
        <Row gutter={8}>
          <Col>
            <Descriptions size={'small'} column={1}>
              {orderDate && orderDate.orderAcceptedDate ? (
                <Descriptions.Item label={<b>Order Accepted Date</b>}>
                  {moment(orderDate.orderAcceptedDate).format('lll')}
                </Descriptions.Item>
              ) : (
                ''
              )}

              {orderDate && orderDate.orderPreparingDate ? (
                <Descriptions.Item label={<b>Order Preparing Date</b>}>
                  {moment(orderDate.orderPreparingDate).format('lll')}
                </Descriptions.Item>
              ) : (
                ''
              )}

              {orderDate && orderDate.orderPreparedDate ? (
                <Descriptions.Item label={<b>Order Prepared Date</b>}>
                  {moment(orderDate.orderPreparedDate).format('lll')}
                </Descriptions.Item>
              ) : (
                ''
              )}

              {orderDate && orderDate.qualityCheckDate ? (
                <Descriptions.Item label={<b>Quality Check Date</b>}>
                  {moment(orderDate.qualityCheckDate).format('lll')}
                </Descriptions.Item>
              ) : (
                ''
              )}

              {orderDate && orderDate.qualityApprovedDate ? (
                <Descriptions.Item label={<b>Quality Approved Date</b>}>
                  {moment(orderDate.qualityApprovedDate).format('lll')}
                </Descriptions.Item>
              ) : (
                ''
              )}

              {orderDate && orderDate.readyToDispatchedDate ? (
                <Descriptions.Item label={<b>Ready To Dispatched Date</b>}>
                  {moment(orderDate.readyToDispatchedDate).format('lll')}
                </Descriptions.Item>
              ) : (
                ''
              )}
              {orderDate && orderDate.dispatched ? (
                <Descriptions.Item label={<b>Dispatched Date</b>}>
                  {moment(orderDate.dispatched).format('lll')}
                </Descriptions.Item>
              ) : (
                ''
              )}
            </Descriptions>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export const AcceptedListAction = props => {
  const [estimatedDate, setEstimatedDate] = useState()
  const [status, setStatus] = useState()
  const [technicianName, setTechnicianName] = useState()
  const [loader, setLoader] = useState(false)

  const handleClose = () => {
    setEstimatedDate(null)
    setStatus(null)
    setTechnicianName(null)
  }

  const handleOk = async () => {
    let { handleSubmit, orderId } = props
    if (!estimatedDate) {
      notification.error({
        message: 'Choose Date'
      })
      return
    }
    if (!status) {
      notification.error({
        message: 'Select Status'
      })
      return
    }
    if (!technicianName) {
      notification.error({
        message: 'Select Technician Name'
      })
      return
    }
    let Obj = {
      estimatedDate: estimatedDate,
      status: status,
      technicianName: technicianName,
      _id: orderId
    }

    setLoader(true)
    let data = await updateOrderDispatchStatusAction(Obj)
    setLoader(false)
    if (!data.error) {
      notification.success({
        message: data.message
      })

      if (handleSubmit) {
        handleSubmit()
      }
      handleClose()
    } else {
      notification.error({
        message: data.message
      })
    }
  }

  return (
    <React.Fragment>
      <div>
        <Row gutter={8}>
          <Col span={24}>
            <Form>
              <Form.Item label={'Estimated Date'}>
                <DatePicker
                  placeholder={'Estimated Date'}
                  onChange={date => {
                    setEstimatedDate(date)
                  }}
                />
              </Form.Item>

              <Form.Item label={'Select Status'}>
                <Select
                  style={{ width: 200 }}
                  placeholder={'Select Status'}
                  onChange={val => {
                    setStatus(val)
                  }}>
                  <Option value={'Order Preparing'}> Order Preparing</Option>
                </Select>
              </Form.Item>
              <Form.Item label={'Technician Name'}>
                <Input
                  style={{ width: 200 }}
                  onChange={val => {
                    setTechnicianName(val.target.value)
                  }}
                />
              </Form.Item>
            </Form>
            <div
              style={{ textAlign: 'right', marginTop: '20px', width: '90%' }}>
              <Button
                onClick={() => {
                  props.onCancel()
                  handleClose()
                }}>
                CANCEL
              </Button>
              <Button
                loading={loader}
                style={{ marginLeft: '10px' }}
                type="primary"
                onClick={() => {
                  handleOk()
                }}>
                SAVE
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
export const PreparingListAction = props => {
  let { isVideo, isPhoto, isStatusChange } = props
  const [filesUpload, setFilesUpload] = useState([])
  const [videoUpload, setVideoUpload] = useState([])
  const [status, setStatus] = useState()
  const [loader, setLoader] = useState(false)

  const handleClose = () => {
    setVideoUpload([])
    setFilesUpload([])
    setStatus(null)
  }
  const handleFileChange = v => {
    if (v.fileList) {
      setFilesUpload(v.fileList)
    }
  }

  const handlePreview = async file => {
    let url
    if (file && file.response && file.response.path) {
      file.response.path = file.response.path.replace(/^public/, '')
      url = `${API_URL}/${file.response.path}`
    } else {
      url = `${file.url}`
    }

    window.open(url, '_blank')
  }
  const handleOk = async () => {
    let { handleSubmit, orderId, isPhoto } = props

    let obj = {
      _id: orderId
    }
    if (isPhoto && !filesUpload.length && !imgSrcs.length) {
      notification.error({
        message: 'Select File To Upload'
      })
      return
    }
    if (isVideo && !videoUpload.length) {
      notification.error({
        message: 'Select File To Upload'
      })
      return
    }
    if (isStatusChange && !status) {
      notification.error({
        message: 'Select Status'
      })
      return
    }

    obj.photos = filesUpload
    obj.videos = videoUpload
    if (status) {
      obj.status = status
    }

    setLoader(true)
    let data = await updateOrderDispatchStatusAction(obj)
    setLoader(false)

    if (!data.error) {
      notification.success({
        message: isStatusChange ? data.message : 'File Uploaded Successfully'
      })

      if (handleSubmit) {
        handleSubmit()
      }
      handleClose()
    } else {
      notification.error({
        message: data.message
      })
    }
  }

  return (
    <React.Fragment>
      <div>
        <Row gutter={8}>
          <Col span={24}>
            <Form>
              <Form.Item label={'Upload Files'} hidden={!isPhoto}>
                <div style={{ marginBottom: '10px' }}>
                  <Upload
                    multiple={true}
                    name={'file'}
                    action={`${API_URL}/filesUploader`}
                    onChange={handleFileChange}
                    onRemove={() => {}}
                    onPreview={handlePreview}
                    fileList={filesUpload}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </div>
              </Form.Item>

              <Form.Item label={'Upload Videos'} hidden={!isVideo}>
                <Upload
                  multiple={true}
                  name={'file'}
                  action={`${API_URL}/filesUploader`}
                  onChange={v => {
                    if (v.fileList) {
                      setVideoUpload(v.fileList)
                    }
                  }}
                  onRemove={() => {}}
                  onPreview={handlePreview}
                  fileList={videoUpload}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>

              <Form.Item hidden={!isStatusChange} label={'Select Status'}>
                <Select
                  style={{ width: 200 }}
                  placeholder={'Select Status'}
                  value={status}
                  onChange={val => {
                    setStatus(val)
                  }}>
                  <Option value={'Order Prepared'}>Order Prepared</Option>
                </Select>
              </Form.Item>
            </Form>

            <div
              style={{ textAlign: 'right', marginTop: '20px', width: '90%' }}>on
              <Button
                onClick={() => {
                  props.onCancel()

                  handleClose()
                }}>
                CANCEL
              </Button>
              <Button
                loading={loader}
                style={{ marginLeft: '10px' }}
                type="primary"
                onClick={() => {
                  handleOk()
                }}>
                SAVE
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
export const UpdateDocketNumber = props => {
  let { isStatusChange } = props
  const [docketNumber, setDocketNumber] = useState()
  const [loader, setLoader] = useState(false)

  const handleClose = () => {
    setDocketNumber(null)
  }

  const handleOk = async () => {
    let { handleSubmit, orderId, isPhoto } = props

    if (!docketNumber) {
      notification.error({
        message: 'Enter Docket Number'
      })
      return
    }
    let obj = {
      _id: orderId,
      dispatchNumber: docketNumber
    }

    setLoader(true)
    let data = await updateOrderAction(obj)
    setLoader(false)

    if (!data.error) {
      notification.success({
        message: isStatusChange
          ? data.message
          : 'Docket Number Changed Successfully'
      })

      if (handleSubmit) {
        handleSubmit()
      }
      handleClose()
    } else {
      notification.error({
        message: data.message
      })
    }
  }

  return (
    <React.Fragment>
      <div>
        <Row gutter={8}>
          <Col span={24}>
            <Form layout={'inline'}>
              <Form.Item label={'Docket Number'}>
                <Input
                  style={{ width: 200 }}
                  onChange={val => {
                    setDocketNumber(val.target.value)
                  }}
                />
              </Form.Item>
            </Form>

            <div
              style={{ textAlign: 'right', marginTop: '20px', width: '90%' }}>
              <Button
                onClick={() => {
                  props.onCancel()

                  handleClose()
                }}>
                CANCEL
              </Button>
              <Button
                loading={loader}
                style={{ marginLeft: '10px' }}
                type="primary"
                onClick={() => {
                  handleOk()
                }}>
                SAVE
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
