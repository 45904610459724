import React, { PureComponent, useState, useEffect } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  Spin,
  Button,
  Card,
  Row,
  Col,
  Checkbox,
  Upload,
  Icon,
  Select
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
//import styles from './styles.less'

// import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import GetAllFormFields from '../../../components/_utils/formUtils'
import { API_URL } from '../../../request'

import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector, goBack } from 'connected-react-router'
import { CodeSandboxCircleFilled } from '@ant-design/icons'

const { TextArea } = Input
const { Option } = Select

// @Form.create()
// class AddStockProduct extends PureComponent {

//     constructor(props) {
//         super(props)
//         this.state = {categoryList: []}

//     }

//     async componentDidMount() {
//         let searchParams = new URLSearchParams(this.props.search);
//         let productId = searchParams.get('id')

//         let {data: categoryList} = await Request.loadCategories();

//         this.setState({categoryList: categoryList})

//         if (productId) {
//             Request.stockProductById({_id: productId}).then(({data, error, message}) => {
//                 if (!error) {
//                     this.setState({
//                         id: productId,
//                         loader: false

//                     })
//                     this.props.form.setFieldsValue({
//                         category: data && data.category,
//                         name: data && data.name,
//                         criticalValue: data && data.criticalValue,
//                         criticalValueTwo: data && data.criticalValueTwo,
//                         quantity: data && data.quantity

//                     })

//                 } else {
//                     this.setState({loader: false})

//                     notification.error({
//                         message: 'Error Getting Data'
//                     })
//                 }
//             })
//         } else {
//             this.setState({loader: false})

//         }

//     }

//     render() {

//         const {} = this.props
//         const {
//             form: {getFieldDecorator, getFieldValue}
//         } = this.props

//         let {categoryList} = this.state
//         const inputType = {
//             fields: [
//                 {
//                     label: 'Category',
//                     key: 'category',
//                     type: 'select',
//                     showSearch: true,
//                     required: true,
//                     keyAccessor: x => x._id,
//                     valueAccessor: x => x.category,
//                     options: categoryList || [],
//                     onChange: async (category) => {
//                         this.props.form.setFieldsValue({
//                             category: category,
//                         })

//                     }

//                 },
//                 {
//                     label: 'Product',
//                     key: 'name',
//                     required: true

//                 },
//                 {
//                     title: 'Quantity',
//                     key: 'quantity',
//                     required: true,
//                     type: 'number',
//                     hidden: this.state.id ? true : false,
//                     onChange: (val) => {
//                         this.props.form.setFieldsValue({quantity: Math.abs(val)})

//                     }

//                 },
//                 {
//                     title: 'Minimum Critical Number',
//                     key: 'criticalValue',
//                     required: true,
//                     type: 'number',
//                     onChange: (val) => {
//                         this.props.form.setFieldsValue({criticalValue: Math.abs(val)})

//                     }

//                 },
//                 {
//                     title: 'Minimum Critical Number',
//                     key: 'criticalValueTwo',
//                     required: true,
//                     type: 'number',
//                     onChange: (val) => {
//                         this.props.form.setFieldsValue({criticalValueTwo: Math.abs(val)})

//                     }

//                 },

//             ]
//         }

//         const formItemLayout = {
//             labelCol: {
//                 xs: {span: 24},
//                 sm: {span: 8},
//                 md: {span: 8}
//             },
//             wrapperCol: {
//                 xs: {span: 24},
//                 sm: {span: 16},
//                 md: {span: 12}
//             }
//         }
//         const formItemLayout1 = {
//             labelCol: {
//                 xs: {span: 24},
//                 sm: {span: 8},
//                 md: {span: 5}
//             },
//             wrapperCol: {
//                 xs: {span: 24},
//                 sm: {span: 16},
//                 md: {span: 12}
//             }
//         }

//         return (
//             <PageHeaderWrapper
//                 title={this.state.id ? 'Update Stock Product' : 'Add Stock Product'}
//             >
//                 <Card bordered={true}>
//                     <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

//                         <Row>

//                             <Col>
//                                 <GetAllFormFields apiurl={API_URL}
//                                                   inputSchema={inputType}
//                                                   formItemLayout={formItemLayout}
//                                                   getFieldDecorator={getFieldDecorator}/>
//                             </Col>

//                         </Row>

//                         <Form.Item style={{marginTop: 32, textAlign: 'center'}}>
//                             <Button size="large" type="primary" htmlType="submit" loading={this.props.loading}
//                                     style={{width: '15%'}}>
//                                 {this.state.id ? `UPDATE` : `SAVE`}

//                             </Button>
//                         </Form.Item>

//                     </Form>
//                 </Card>
//             </PageHeaderWrapper>
//         )
//     }
// }

// const mapStateToProps = ({global, router}) => ({
//     loading: global.buttonLoading,
//     categories: global.categories,
//     search: router.location.search
// })
// const mapDispatchToProps = dispatch => {
//     return {
//         dispatch
//     }
// }

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(AddStockProduct)

const AddStockProduct = () => {
  const [id, setId] = useState(null)
  const [loader, setLoader] = useState(true)
  const [loading, setLoading] = useState(false)
  const [quantity, setQuantity] = useState(null)
  const [categoryList, setCategoryList] = useState([])

  const [form] = Form.useForm()
  let {
    resetFields,
    setFieldsValue,
    getFieldValue,
    getFieldDecorator,
    validateFieldsAndScroll
  } = form
  useEffect(() => { 
    editFunction()
  }, [])
  const editFunction = async () => {
    let searchParams = new URLSearchParams(window.location.search)
    let productId = searchParams.get('id')
    let { data: categoryList } = await Request.loadCategories()
    setCategoryList(categoryList)
    // this.setState({categoryList: categoryList})

    if (productId) {
      Request.stockProductById({ _id: productId }).then(
        ({ data, error, message }) => {
          if (!error) {
            setId(productId)
            setLoader(false)
            // this.setState({
            //   id: productId,
            //   loader: false
            // })
            setFieldsValue({
              category: data && data.category,
              name: data && data.name,
              criticalValue: data && data.criticalValue,
              criticalValueTwo: data && data.criticalValueTwo,
              stock: data && data.stock,
            })
          } else {
            setLoader(false)
            // this.setState({ loader: false })

            notification.error({
              message: 'Error Getting Data'
            })
          }
        }
      )
    } else {
      setLoader(false)
      //   this.setState({ loader: false })
    }
  }
  let handleSubmit = async valData => {
    console.log(valData, '------')
    setLoading(true)
    //   validateFieldsAndScroll(async (err, valData) => {
    //         if (!err) {
    let values = _.clone(valData)
    values.stock = valData.quantity
    // dispatch(showLoader())

    let x = null

    if (id) {
      values._id = id
      x = await Request.updateStockProduct(values)
    } else {
      x = await Request.addStockProduct(values)
    }

    // dispatch(hideLoader())

    if (!x.error) {
      notification.success({
        message: id ? x.message : x.message
      })

      resetFields()

      setFieldsValue({})
      // if (this.state.id) {
      //     dispatch(goBack())
      // }
    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message
      })
    }

    //     }
    // })
    setLoading(false)
  }

  const inputType = {
    fields: [
      {
        label: 'Category',
        key: 'category',
        type: 'select',
        width: 200,
        showSearch: true,
        required: true,
        keyAccessor: x => x._id,
        valueAccessor: x => x.category,
        options: categoryList || [],
        onChange: async category => {
          setFieldsValue({
            category: category
          })
        }
      },
      {
        label: 'Product',
        key: 'name',
        required: true
      },
      {
        label: 'Quantity',
        key: 'stock',
        required: true,
        type: 'number',
        // hidden: this.state.id ? true : false,
        onChange: (val) => {
            this.props.form.setFieldsValue({stock: Math.abs(val)})

        }

    },
    {
        label: 'Critical Value One',
        key: 'criticalValue',
        required: true,
        type: 'number',
        onChange: (val) => {
            this.props.form.setFieldsValue({criticalValue: Math.abs(val)})

        }

    },
    {
        label: 'Critical Value Two',
        key: 'criticalValueTwo',
        required: true,
        type: 'number',
        onChange: (val) => {
            this.props.form.setFieldsValue({criticalValueTwo: Math.abs(val)})

        }
      }
    ]
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 12 }
    }
  }
  const formItemLayout1 = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 5 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 12 }
    }
  }
  return (
    <PageHeaderWrapper
      title={id ? 'Update Stock Product' : 'Add Stock Product'}>
      <Card bordered={true}>
        <Form
          //   onFinish={handleSubmit}
          form={form}
          onFinish={val => handleSubmit(val)}
          hideRequiredMark={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ marginTop: 8 }}
          {...formItemLayout1}>
          <GetAllFormFields
            apiurl={API_URL}
            inputSchema={inputType}
            // formItemLayout={formItemLayout}
            getFieldDecorator={getFieldDecorator}
          />

          <Form.Item style={{ marginTop: 32, textAlign: 'center' }}>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: '25%' }}>
              {id ? `UPDATE` : `SAVE`}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </PageHeaderWrapper>
  )
}
export default AddStockProduct
