import React, { useState, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils4'
import {
  Button,
  Card,
  Tooltip,
  Tag,
  Drawer, } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { getAllComplaintsAction } from '../actions'
import {
  ProfileOutlined,
  IdcardOutlined,
  MessageOutlined
} from '@ant-design/icons'

import { warrantyStatus, getComplaintColor } from '../../../request'
import { CommentLogs, StatusLogs, FilesLogs } from './logs'

const CloseComplaints = () => {
  const tableRef = useRef()
  const [count, setCount] = useState(0)
  const [complaintId, setComplaintId] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [drawerLogsVisible, setdrawerLogsVisible] = useState(false)
  const [allComments, setAllComments] = useState([])
  const [allStatusLogs, setAllStatusLogs] = useState([])
  const [drawerUploadView, setDrawerUploadView] = useState(false)
  const [imageArray, setImageArray] = useState([])

  const showStatusLogs = () => {
    setDrawerVisible(true)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }


  const showDrawer = () => {
    setDrawerVisible(true)
  }

  const onClose = () => {
    setDrawerVisible(false)
    setdrawerLogsVisible(false)
    setDrawerUploadView(false)
  }


  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.status = 'Close'
      if (!params.sortField) {
        params.sortField = 'isReopened'
        params.sortOrder = 'descend'
      }
      let data = await getAllComplaintsAction({
        ...params, regExFilters: ['complaintId','complaintRaisedByName', 'complaintDetails', 'productName']
      })
      setCount(data.total)
      resolve(data)
    })
  }

  const myColumns = [
    {
      title: 'Complaint ID',
      key: 'complaintId',
      width: 140,
      searchTextName: 'complaintId',
      render: (text,record)=>{
        return <div>{record.complaintId}</div>
      }
    },
    {
      title: 'Lead Name',
      dataIndex: 'leadName',
      key: 'leadName',
      width: 200,
      searchTextName: 'lead name',
      fixed: 'left'
    },
    {
      title: 'Contact Details',
      key: 'data',
      width: 200,
      render: (text, record) => {
        return (<>
          {record.email}
          <br />
          {record.mobile}
          <br />
          {record.state}
        </>)
      },
      searchTextName: 'Mobile Number'
    },
    {
      title: 'RO Name',
      width: 100,
      dataIndex: 'complaintRaisedByName',
      key: 'complaintRaisedByName',
      searchTextName: 'ro name'
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      width: 200,
      key: 'productName',
      searchTextName: 'product name'

    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 100,
      key: 'type'
    },
    {
      title: 'Complaint Details',
      width: 200,
      dataIndex: 'complaintDetails',
      key: 'complaintDetails',
      searchTextName: 'details'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      key: 'status',
      render: (val) => {
        return <Tag color={getComplaintColor(val)}>{val}</Tag>
      }
    },
    {
      title: 'Warranty',
      key: 'warranty',
      width: 150,
      dataIndex: 'warranty',
      filters: _.map(warrantyStatus, (x) => {
        return { text: x, value: x }
      }),
      render: (val) => {
        return <Tag>{val}</Tag>
      }

    },
    {
      title: 'Latest Comment',
      key: 'comments',
      width: 200,
      render: (comments) => {
        return (<>
          {comments.length ? comments[0].message : ''}
          {<br />}
          <small>{comments.length ? moment(comments[0].time).format('lll') : ''}</small>
          {<br />}
          {comments.length ?
            <Button type="primary" style={{ padding: '0 3px', fontSize: '10px', height: 'fit-content' }}
                    onClick={() => {
                      setAllComments(comments)
                      showDrawer()
                    }
                    }>
              View Comments
            </Button> : ''}
        </>)
      }
    },
    {
      title: 'Created At',
      key: 'createdAt',
      type: 'date',
      width: 150,
      sorter: true,
      render: (createdAt) => {
        return (<>
          {moment(createdAt).format('lll')}
        </>)
      }
      // searchDateName: 'Mobile Number'
    },

    {
      key: 'actions',
      title: 'Actions',
      fixed: 'right',
      width: 100,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip title="View Status">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                onClick={() => {
                  setAllStatusLogs(record.statusLogs)
                  setdrawerLogsVisible(true)
                  showStatusLogs()
                }}
                icon={<ProfileOutlined />}
              />
            </Tooltip>
            <Tooltip title="View Files">
              <Button
                shape="circle"
                style={{ marginRight: 6, marginTop: 6 }}
                size="small"
                onClick={() => {
                  setDrawerUploadView(true)
                  setImageArray(record.imageArray)

                }}
                icon={<IdcardOutlined />}
              />
            </Tooltip>

            {/* <Tooltip title="Add Comment">
              <Button
                shape="circle"
                style={{ marginRight: 6, marginBottom: 6 }}
                size="small"
                onClick={() => {
                setComplaintId(record._id)
                showModal()
              }}
              icon={<MessageOutlined />}
              />
            </Tooltip> */}
          </React.Fragment>
        )
      }
    }

  ]
  return (<>
    <PageHeaderWrapper title={`Close Complaints (${count || 0})`}>
      <Card bordered={true}>

        <TableComp
          ref={tableRef}
          columns={myColumns}
          extraProps={{ scroll: { x: 1300 } }}
          apiRequest={apiRequest}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ['10', '25', '50', '100'],
            showSizeChanger: true
          }}
        />


        <Drawer title="All Comments" width={800} placement="right" onClose={onClose} visible={drawerVisible}>

          <CommentLogs allComments={allComments} />

        </Drawer>

        <Drawer title="All Status Logs" width={800} placement="right" onClose={onClose} visible={drawerLogsVisible}>
          <StatusLogs allStatusLogs={allStatusLogs} />
        </Drawer>

        <Drawer title="File Logs" width={800} placement="right" onClose={onClose} visible={drawerUploadView}>
          <FilesLogs imageArray={imageArray} />
        </Drawer>

      </Card>
    </PageHeaderWrapper>
  </>)
}

export default CloseComplaints
