import axios from 'axios'
import React from 'react'


export let API_URL = 'http://localhost:8343'
export const pageTitle = 'HT-Complaint-CRM'

export const timeStampFormat = 'DD/MM/YYYY'

if (process.env.REACT_APP_CUSTOM_NODE_ENV == 'staging') {

  API_URL = 'https://devsales-r.ht-india.com'
}

if (process.env.NODE_ENV == 'production') {
  API_URL = 'https://sales-r.ht-india.com'
}
export const getWarrantyStatusColor = (warranty) => {
  let returnColor = ''

  switch (warranty) {
    case 'Out Of Warranty':
      returnColor = '#FF3E4D'
      break
    case 'Unknown Warranty':
      returnColor = '#ff8c00'
      break
    case 'In Warranty':
      returnColor = 'green'
      break
    default:
      returnColor = 'green'
  }

  return returnColor
}
export const warrantyStatusList = [
  'In Warranty',
  'Out Of Warranty',
  'Unknown Warranty',
  'Brand New'
]
let authAxios = axios.create({
  baseURL: API_URL
})
export const warrantyStatus = [
  'In Warranty',
  'Out Of Warranty',
  'Unknown Warranty',
  'Brand New'
]
export const getComplaintColor = status => {
  let returnColor = ''

  switch (status) {
    case 'Close':
      returnColor = '#FF3E4D'
      break
    case 'Fixed By Technician':
      returnColor = '#ff8c00'
      break
    case 'Complaint To Head Office':
      returnColor = '#FF6347'
      break
    case 'Transfer To ASC':
      returnColor = '#008080'
      break
    case 'Open':
      returnColor = '#0000ff'
      break
    default:
      returnColor = 'green'
  }

  return returnColor
}

export const carriers = ['Fedex', 'DHL', 'UPS', 'USPS', 'BlueDart', 'DTDC']
export const orderStatus = [
  'Order Received',
  'Order Accepted',
  'Order Preparing',
  'Order Prepared',
  'Quality Check',
  'Quality Approved',
  'Ready To Dispatched',
  'Dispatched'
]
export const getStatusColor = status => {
  let returnColor = ''

  switch (status) {
    case 'Order Received':
      returnColor = 'yellow'
      break
    case 'Order Preparing':
      returnColor = '#ff8c00'
      break
    case 'Order Accepted':
      returnColor = '#26ae60'
      break
    case 'Order Prepared':
      returnColor = '#00CCCD'
      break
    case 'Quality Check':
      returnColor = '#8B78E6'
      break
    case 'Ready To Dispatched':
      returnColor = '#9c27b0'
      break
    case 'Dispatched':
      returnColor = '#96FDDE'
      break
    case 'Quality Approved':
      returnColor = '#96FDDE'
      break
    default:
      returnColor = 'green'
  }

  return returnColor
}

export function downloadData(url, filename) {
  fetch(url).then(function(t) {
    return t.blob().then(b => {
      let a = document.createElement('a')
      a.href = URL.createObjectURL(b)
      a.setAttribute('download', filename)
      a.click()
    })
  })
}

export function truncate(str, n) {
  if (str && str.length) {
    return str.length > n ? str.substr(0, n - 1) + '...' : str
  }
  return ''
}

export const getToken = () => {
  return {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }
}

class Request {
  constructor() {
    // asyncStorage
    //   .getToken()
    //   .then((data) => {
    //     localStorage.getItem('token') = data
    //   })
  }

  v

  deleteHeads(data) {
    return new Promise((next, error) => {
      authAxios
        .delete(
          '/heads',
          { data: { _id: data } },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          }
        )
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          error(err)
          this.error(err)
        })
    })
  }

  error = err => {
    try {
      if (err.response.status === 401) {
        localStorage.clear()
      }
    } catch (e) {
    }
  }

  getClaimHistoryArrByMachineId(data) {
    return new Promise(next => {
      authAxios
        .get('https://warranty.scizers.com/saleProduct', {
          params: { ...data },
          ...getToken()
        })
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getComplaintId(data) {
    return new Promise((next) => {
      authAxios
        .get('/getComplaintId', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }


  getAllProducts(data) {
    return new Promise(next => {
      authAxios
        .get('/product', { params: { ...data }, ...getToken() })
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  leadsSearch(data) {
    return new Promise(next => {
      authAxios
        .put('/leadSearch', { data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addComplaint(data) {
    return new Promise(next => {
      authAxios
        .post('/complaint', data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  login(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/login', data)
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  ledgerByProductId(data) {
    return new Promise(next => {
      authAxios
        .get('/ledgerByProductId/' + data.productId, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  addStockProduct(data) {
    return new Promise(next => {
      authAxios
        .post('/stockProduct', data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  deleteCategory(data) {
    return new Promise(next => {
      authAxios
        .delete(`/category/` + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getOrderDispatchCount(data) {
    return new Promise(next => {
      authAxios
        .get('/dasboardCountCCrm', { params: { ...data }, ...getToken() })
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  getAllCategories(data) {
    return new Promise(next => {
      authAxios
        .get('/category', { params: { ...data }, ...getToken() })
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getComplaintCount(data) {
    return new Promise(next => {
      authAxios
        .get('/complaintStatsCCrm', { params: { ...data }, ...getToken() })
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  addCategory(data) {
    return new Promise(next => {
      authAxios
        .post('/category', data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  updateStockProduct(data) {
    return new Promise(next => {
      authAxios
        .put('/stockProduct/' + data._id, data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  stockProductById(data) {
    return new Promise(next => {
      authAxios
        .get('/stockProduct/' + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  loadCategories(data) {
    return new Promise(next => {
      authAxios
        .get('/allCategories', { params: { ...data }, ...getToken() })
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          this.error(err)
        })
    })
  }
  deleteInwardOutwardItem(data) {
    return new Promise(next => {
      authAxios
        .delete(`/inwardId/` + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  downloadComplaint(data) {
    return new Promise(next => {
      console.log(data)
      authAxios

        .post('/complaint/download', data)
        .then(d => {
          if (d.data) {

            window.open(d.data.url, 'download')
          }
          next('done')
        })
        .catch(err => {
          this.error(err)
        })
    })
  }
  downloadStock(data) {
    return new Promise(next => {
      authAxios
        .post('/stockPdf', data)
        .then(d => {
          if (d.data) {
            let linkValue = `${API_URL}/${d.data.data}`
            window.open(linkValue, 'download')
          }
          next('done')
        })
        .catch(err => {
          this.error(err)
        })
    })
  }

  downloadWarranty(data) {
    return new Promise(next => {
      authAxios
        .post('/warrantyPdf',data)
        .then(d => {
          if (d.data) {
            let linkValue = `${API_URL}/${d.data.data}`
            window.open(linkValue, 'download')
          }
          next('done')
        })
        .catch(err => {
          // this.error(err)
        })
    })
  }


  inwardOutwardList(data) {
    return new Promise(next => {
      authAxios
        .get('/inward', { params: { ...data }, ...getToken() })
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          this.error(err)
        })
    })
  }
  getAllStockList(data) {
    return new Promise(next => {
      authAxios
        .get('/allStock', { params: { ...data }, ...getToken() })
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          this.error(err)
        })
    })
  }
  getAllStockProductList(data) {
    return new Promise((next) => {
      console.log("shsjh")
      authAxios
        .get('/allStockProduct', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          this.error(err)
        })
    })
  }
  getAllStockProducts(data) {
    return new Promise(next => {
      authAxios
        .get('/stockProduct', { params: { ...data }, ...getToken() })
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  getNextInwardOutwardFormNo(data) {
    return new Promise(next => {
      let user = JSON.parse(localStorage.getItem('user'))
      authAxios
        .get('/nextDispatchId', { params: { ...data }, ...getToken() })
        .then(d => {
          console.log('res isssssss', d)
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getInwardOutwardById(data) {
    return new Promise(next => {
      authAxios
        .get('/inwardId/' + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  addInward(data) {
    return new Promise(next => {
      authAxios
        .post('/inward', data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  updateInward(data) {
    return new Promise(next => {
      authAxios
        .post('/inwardUpdate', data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  inwardOutwardProducts(data) {
    console.log(data)
    return new Promise(next => {
      authAxios
        .get('/inwardOutwardProducts', { params: { ...data }, ...getToken() })
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  loadUser(data) {
    return new Promise(next => {
      authAxios
        .get('/allUsers', { params: { ...data }, ...getToken() })
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          this.error(err)
        })
    })
  }
  updateInwardOutwardForm(data) {
    return new Promise(next => {
      authAxios
        .put('/dispatch/' + data._id, data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteInwardOutward(data) {
    return new Promise(next => {
      authAxios
        .delete(`/dispatch/` + data._id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  updateProductWarranty(id,data) {
    return new Promise(next => {
      authAxios
        .put(`/updateWarranty/` + id, data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getProductWarranty(id,data) {
    return new Promise(next => {
      authAxios
        .get(`/updateWarranty/` + id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteProductWarranty(_id) {
    return new Promise(next => {
      authAxios
        .delete(`/updateWarranty/` + _id, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  EditProductId(data){
    return new Promise(next =>{
      authAxios.put('/editProductId',data,getToken())
      .then((d)=>{
        next(d.data);
      })
      .catch(err=>{
        next({error: true,err});
        this.error(err);
      })
    })
  }
}

export default new Request()
