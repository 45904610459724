
import React, { PureComponent, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  notification,
  Anchor,
  Spin,
  Select,
  Tag,
  Button,
  Table,
  Card,
  Typography,
  Row,
  Col,
  Tooltip,
  Popconfirm,
  Checkbox,
  AutoComplete,
  Radio,
  Timeline,
  Drawer,
  DatePicker,
  InputNumber
} from 'antd'
import _ from 'lodash'
import GetAllFormFields from '../../../components/_utils/formUtils'
import moment from 'moment'

import { useEffect } from 'react'

import { API_URL } from '../../../request'

import 'react-quill/dist/quill.snow.css'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import styles from '../all/styles.less'
import { connect } from 'react-redux'
import { DeleteOutlined } from '@ant-design/icons'

const FormItem = Form.Item
const { Option } = Select
const { Text, Paragraph } = Typography
const { TextArea } = Input

const AddOutward = () => {
  const [visible, setVisible] = useState(false)
  const [buttonReload, setButtonReload] = useState(false)
  const [loader, setLoader] = useState(true)
  const [description, setDescription] = useState('')
  const [quantity, setQuantity] = useState('')
  const [recordDate, setRecordDate] = useState(null)
  const [totalData, setTotalData] = useState([])
  const [totalProductList, setTotalProductList] = useState([])
  const [productId, setProductId] = useState(null)
  const [product, setProduct] = useState(null)
  const [_id, setId] = useState(null)

  const pageSubmit = async () => {
    setButtonReload(true)
    let params = { recordDate, totalData }
    params.type = 'Outward'
    if (recordDate === null) {
      notification.error({ message: 'Please Select a Date' })
    } else {
      if (totalData.length > 0) {
        if (_id === null) {
          console.log('sjhjshjhjs')
          const { error } = await Request.addInward(params)
          if (!error) {
            notification.success({ message: 'Outward Added Successfully ' })
            setFieldsValue({
              recordDate: moment()
            })
            setRecordDate(moment())
            setTotalData([])
          } else {
            notification.error({ message: 'Error Occured' })
          }
        } else {
          params.inwardId = _id
          const { error } = await Request.updateInward(params)
          if (!error) {
            setButtonReload(true)

            notification.success({ message: 'Outward Updated Successfully ' })
          } else {
            notification.error({ message: 'Error Occured' })
          }
        }
      } else {
        notification.error({ message: 'Please Add Product' })
      }
    }
    setButtonReload(false)
  }
  const handleDelete = val => {
    if (_id !== null && val._id) {
      const { error } = Request.deleteInwardOutwardItem(val)
    }

    let tt = totalData
    tt = _.reject(tt, val)
    setTotalData(tt)
  }

  const handleSubmit = e => {
    let data = {
      quantity: quantity,
      description: description,
      product: product,
      productId
    }

    if (product == null) {
      notification.error({ message: 'Please Select A Product' })
    } else {
      if (totalData && totalData.length > 0) {
        let gh = [...totalData, data]
        console.log(gh, '---------')
        setTotalData(gh)
      }
      if (totalData && totalData.length < 1) {
        setTotalData([data])
      }
      setProduct(null)
      setQuantity('')
      setDescription('')
      setFieldsValue({
        productId: '',
        quantity: '',
        description: ''
      })
    }
  }

  useEffect(() => {
    editFunction()
  }, [])
  const [form] = Form.useForm()
  const [formRef] = Form.useForm()
  let { resetFields, setFieldsValue, getFieldValue, getFieldDecorator } = form

  const editFunction = () => {
    let searchParams = new URLSearchParams(window.location.search)
    let dispatchId = searchParams.get('id')
    if (dispatchId) {
      Request.getInwardOutwardById({ _id: dispatchId }).then(
        ({ data: dispatchData, error, message, totalData: totalTableData }) => {
          if (!error) {
            setId(dispatchData._id)
            setLoader(false)
            setTotalData(totalTableData)
            setRecordDate(dispatchData.recordDate)

            setFieldsValue({
              recordDate: dispatchData && moment(dispatchData.recordDate)
            })
          } else {
            setLoader(false)

            notification.error({
              message: 'Error Getting Data'
            })
          }
        }
      )
    } else {
      Request.getNextInwardOutwardFormNo({ type: 'Outward' }).then(data => {
        if (!data.error) {
          setRecordDate(moment())

          setFieldsValue({
            serialNumber: data.data,
            recordDate: moment()
          })
        }
      })
      setLoader(false)
    }
    Request.getAllStockProducts({
      results: 2000
    }).then(({ data: stockProductList }) => {
      setTotalProductList(stockProductList || [])
    })
  }
  const inputTypes = {
    fields: [
      {
        label: 'Record Date',
        key: 'recordDate',
        type: 'date',

        required: true,

        customProps: {
          onChange: e => {
            setRecordDate(e)
          }
        }
      }
    ]
  }
  const refColumns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      key: 'sno',
      width: 70,
      render: (value, item, index) => {
        if (item && item.isLast != true) {
          return index + 1
        }
      }
    },
    {
      title: 'Product',
      key: 'product',
      dataIndex: 'product'
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity'
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description'
    },

    {
      title: 'Action',
      key: 'operation',
      width: 80,
      render: (item, record) => {
        if (record && record.isLast != true) {
          return (
            <React.Fragment>
              <Tooltip title="Delete Product">
                <Popconfirm
                  title="Are you sure delete this Product?"
                  onConfirm={() => {
                    handleDelete(record)
                  }}
                  onCancel={() => {
                    console.log()
                  }}
                  okText="Yes"
                  cancelText="No">
                  <Button shape="circle" size="small" type={'danger'}>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Tooltip>
            </React.Fragment>
          )
        }
      }
    }
  ]
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 12 }
    }
  }
  return (
    <PageHeaderWrapper title={_id ? 'Update Outward Form' : 'Add Outward Form'}>
      <Card>
        <Row>
          <Col span={6}>
          <Form form={form}>
          <GetAllFormFields
              apiUrl={API_URL}
              inputSchema={inputTypes}
              getFieldDecorator={getFieldDecorator}
            />
          </Form>
            
          </Col>
        </Row>

        <Row>
          <Card>
            <Form form={form} layout="inline" onFinish={handleSubmit}>
              <Row type="flex" justify="space-between" gutter={4}>
                <Col>
                  <Form.Item
                    label="Product"
                    name="productId"
                    {...formItemLayout}>
                    <Select
                      value={product}
                      showSearch
                      optionFilterProp="children"
                      style={{
                        width: 200
                      }}
                      onSelect={e => {
                        let productObj = totalProductList.find(
                          obj => obj._id === e
                        )
                        if (productObj) {
                          setProduct(productObj.name)
                          setProductId(e)
                        }
                      }}>
                      {totalProductList.map(item => {
                        return <Option value={item._id}>{item.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Quantity" name="quantity">
                    <InputNumber
                      value={quantity}
                      style={{
                        width: 200
                      }}
                      onChange={e => setQuantity(e)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Description"
                    name="description"
                    {...formItemLayout}>
                    <TextArea
                      value={description}
                      style={{
                        width: 250
                      }}
                      onChange={e => setDescription(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button
                      style={{
                        width: 80
                      }}
                      htmlType="submit"
                      type="primary">
                      Add
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Row>

        <div style={{ marginTop: '30px' }}>
          <Table
            bordered={true}
            rowKey={x => x._id}
            columns={refColumns}
            dataSource={totalData || []}
            pagination={false}
          />
        </div>
        <Row type="flex" justify="center" style={{ marginTop: '30px' }}>
          <Button
            htmlType="submit"
            onClick={() => pageSubmit()}
            type="primary"
            loading={buttonReload}
            style={{
              width: 140
            }}>
            {_id !== null ? 'Update Outward' : 'Save Outward '}
          </Button>
        </Row>
      </Card>
    </PageHeaderWrapper>
  )
}

export default AddOutward
