import React, { useState } from 'react'
import {
  AutoComplete, Button, Form, Modal, notification, Select, Upload
} from 'antd'
import 'react-quill/dist/quill.snow.css'
import { postStatusAction } from '../actions'

const { Option } = Select

const changeStatus = (props) => {
  const [statusChange, setStatusChange] = useState()
  const [loader, setLoader] = useState(false)


  const handleOkStatus = async () => {
    let { complaintId, handleChangeStatus } = props
    if (!statusChange) {
      notification.error({
        message: 'Select Status'
      })
      return
    }
    let statusObj = {
      complaintId: complaintId,
      status: statusChange
    }
    setLoader(true)
    let { data } = await postStatusAction(statusObj)
    setLoader(false)
    if (!data.error) {
      notification.success({
        message: data.message
      })
      setStatusChange('')
      if (handleChangeStatus) {
        handleChangeStatus()
      }

    } else {
      notification.error({
        message: data.message
      })
    }
  }
  const handleChangeStatus = (e) => {
    setStatusChange(e)
  }


  return (
    <div>
      <Form>
        <Form.Item label={'Status'}>

          <Select placeholder={'Select Status'} value={statusChange}
                  onChange={handleChangeStatus}>
            <Option value="Open">Open</Option>
            <Option value="Complaint To Head Office">Complaint To Head Office</Option>
            {/* 'Complaint To Head Office','Transfer To ASC', */}
            <Option value="Transfer To ASC">Transfer To ASC</Option>
            <Option value="Fixed By Technician">Fixed By Technician</Option>
          </Select>

        </Form.Item>

        <div style={{ textAlign: 'right', marginTop: '20px', width: '90%' }}>

          <Button onClick={() => {
            props.onCancel()
            setStatusChange(undefined)
          }}>
            CANCEL
          </Button>
          <Button style={{ marginLeft: '10px' }} type="primary" onClick={() => {
            handleOkStatus()
          }} loading={loader}>
            SAVE
          </Button>

        </div>

      </Form>


    </div>
  )
}
export default changeStatus




