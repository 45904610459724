import React, { PureComponent, useEffect, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  Spin,
  Button,
  Card,
  Row,
  Col,
  Checkbox,
  Upload,
  Icon,
  Select,
  AutoComplete,
  Tag,
  Radio,
  Descriptions
} from 'antd'
import _ from 'lodash'
import moment from 'moment'

import GetAllFormFields from '../../../components/_utils/formUtils'
import { API_URL } from '../../../request'

import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request, {
  getWarrantyStatusColor,
  warrantyStatusList
} from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector, goBack } from 'connected-react-router'
import { useForm } from 'rc-field-form'

import styles from './style.less'
import { SearchOutlined } from '@ant-design/icons'
const { Option } = Select

const AddComplain = () => {
  const [form] = Form.useForm()
  const [id, setId] = useState(null)
  const [leadId, setLeadId] = useState(null)

  const [leadNamesFiltered, setLeadNamesFiltered] = useState([])
  const [showSpin, setShowSpin] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showEditButton, setShowEditButton] = useState(false)
  const [searchLoader, setSearchLoader] = useState(false)
  const [leadNames, setleadNames] = useState([])
  const [productList, setProductList] = useState([])
  const [machineId, setMachineId] = useState(null)
  const [productId, setProductId] = useState(null)
  const [billingData, setBillingData] = useState(null)
  const [shippingData, setShippingData] = useState(null)
  const [warrantyStatus, setWarrantyStatus] = useState(null)
  const [complaintId,setComplaintId] = useState(null)

  const [productInitial, setProductInitial] = useState(null)
  const [warrantyCheckData, setWarrantyCheckData] = useState(null)

  let {
    resetFields,
    setFieldsValue,
    getFieldsValue,
    getFieldValue,
    getFieldDecorator
  } = form
  const searchLead = _.debounce(handleDealsSearch, 1000, true)
  async function handleDealsSearch(test) {
    let obj = {}
    setShowSpin(true)

    if (test === '') {
      setLeadNamesFiltered(leadNames, () => {
        setShowSpin(false)
        setIsEdited(false)
        setShowEditButton(false)
        setBillingData(null)
        setShippingData(null)
      })

      return false
    }
    obj.text = test
    obj.dontCheckStatus = true
    let { data, error, message } = await Request.leadsSearch(obj)
    if (!error) {
      setLeadNamesFiltered(data)
      setShowSpin(false)
    }
  }

  let handleSubmit = async valData => {
    setLoading(true)
    let values = _.clone(valData)

    values.complaintId=complaintId;

    let x = null
    values.leadId = leadId
    values.productId = productId

    if (id) {
      values._id = id
      //  x = await Request.updateCity(values)
    } else {
      x = await Request.addComplaint(values)
    }

    if (!x.error) {
      notification.success({
        message: id ? x.message : x.message
      })

      form.resetFields()

      form.setFieldsValue({ date: moment(), type: 'Complaint' })
      setLeadNamesFiltered([])
      setMachineId('')
      setProductInitial('')
      setWarrantyCheckData('')
      getNextId()
    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message
      })
    }
    setLoading(false)
  }

  const getClaimHistory = async machineId => {
    setSearchLoader(true)

    let x = await Request.getClaimHistoryArrByMachineId({ machineId })
    setSearchLoader(false)

    if (!x.error) {
      if (x.data && x.data.length) {
        setWarrantyCheckData(x.data[0])

        let warrantyCheck = moment(x.data[0].warranty)
        if (
          moment().isSame(warrantyCheck) ||
          moment().isBefore(warrantyCheck)
        ) {
          form.setFieldsValue({ warranty: 'In Warranty' })
          setWarrantyStatus('In Warranty')
        } else {
          form.setFieldsValue({ warranty: 'Out Of Warranty' })
          setWarrantyStatus('Out Of Warranty')
        }
      }
    } else {
      form.setFieldsValue({ warranty: 'Unknown Warranty' })
      setWarrantyCheckData({
        saleData: '',
        warranty: '',
        productName: form.getFieldValue('productName')
      })

      setWarrantyStatus('Unknown Warranty')
    }
  }

  const onProductSelect = async val => {
    let selectedProduct = productList.find(obj => obj._id === val)
    if (selectedProduct) {
      setFieldsValue({
        productName: selectedProduct.name
      })

      setProductId(selectedProduct && selectedProduct._id)
      setProductInitial(selectedProduct && selectedProduct.productInitial)
    }
  }
  let onDealSelect = async val => {
    let selectedQuote = leadNamesFiltered.find(obj => obj._id === val)
    if (selectedQuote) {
      setFieldsValue({
        leadName: selectedQuote.title,
        contactName:
          selectedQuote &&
          selectedQuote.contact &&
          selectedQuote.contact.firstName,
        mobile:
          selectedQuote &&
          selectedQuote.contact &&
          selectedQuote.contact.mobile,
        email:
          selectedQuote && selectedQuote.contact && selectedQuote.contact.email,
        state: selectedQuote && selectedQuote.state
      })
      setLeadId(selectedQuote._id)
    }
  }

  let getNextId = async() => {
    await Request.getComplaintId({}).then((data) => {
      setComplaintId({
        complaintId:data.data,
      })
    });

    console.log("sajgfahg",complaintId);
  }

  useEffect(async () => {
    form.setFieldsValue({ date: moment(), type: 'Complaint' })
    getNextId();
    await Request.getAllProducts({ results : 10000 }).then(
      ({ data: productList }) => {
        setProductList(productList || [])
      }
    )
  }, [])
  
  const inputType = {
    fields: [
      
      {
        label: 'Contact Name',
        key: 'contactName'
      },
      {
        label: 'Mobile No',
        key: 'mobile',
        customProps: { disabled: true }
      },
      {
        label: 'Email',
        key: 'email'
      },
      {
        label: 'State',
        key: 'state'
      }
    ]
  }
  const inputType1 = {
    fields: [
      {
        label: 'Warranty',
        key: 'warranty',
        required: true,
        type: 'select',
        disabled: true,
        options: warrantyStatusList,
        onChange: val => {
          form.setFieldsValue({ warranty: val })
        }
      },
      {
        label: 'Complaint Date',
        key: 'date',
        required: true,
        type: 'date'
      },
      {
        label: 'Complaint Details',
        key: 'complaintDetails',
        required: true,
        type: 'textarea',
        rows: 8
      },
      {
        label: 'Photos',
        key: 'images',
        type: 'file',
        limit: 10
      }
    ]
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 12 }
    }
  }
  return (
    <>
      <PageHeaderWrapper title={id ? 'Update Complaint' : 'Add Complaint'}>
        <Card bordered={true}>
          <Form
            form={form}
            onFinish={handleSubmit}
            hideRequiredMark={false}
            style={{ marginTop: 8 }}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}>
            <Row>
              <Col span={16}>

                <Form.Item {...formItemLayout} label="Complaint ID">
                  <Input disabled value={complaintId && complaintId.complaintId}/>
                </Form.Item>

                





                <Form.Item
                  label="Lead Name"
                  name="leadName"
                  {...formItemLayout}
                  required={true}>
                  <AutoComplete
                    notFoundContent={showSpin && <Spin />}
                    autoComplete={'none'}
                    onSelect={onDealSelect}
                    onSearch={searchLead}
                    placeholder={'Lead Name'}>
                    {leadNamesFiltered &&
                      leadNamesFiltered.map((val, item) => (
                        <Option value={val._id}>
                          <>
                            <div>
                              <b>{`${val.title} (${val.id})`}</b>
                            </div>
                            <div
                              className={styles.autoCompleteList}
                              style={{ marginTop: '0px' }}>{`${
                              val.contact && val.email
                                ? val.contact.emacontactil
                                : ''
                            }`}</div>
                            <div
                              className={
                                styles.autoCompleteList
                              }>{`${val.contact &&
                              val.contact.firstName} (${val.contact &&
                              val.contact.mobile})`}</div>
                            <div
                              className={
                                styles.autoCompleteList
                              }>{`Company: ${val.company || ''}`}</div>
                            <Tag>{`${moment(val.createdAt).format(
                              'DD-MM-YYYY HH:mm'
                            )}`}</Tag>
                          </>
                        </Option>
                      ))}
                  </AutoComplete>
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                  label={'Select Product'}
                  name="productName"
                  required={true}>
                  <Input.Group compact>
                    <AutoComplete
                      style={{ width: '45%' }}
                      notFoundContent={showSpin && <Spin />}
                      autoComplete={'none'}
                      onChange={() => {
                        setMachineId('')
                        setWarrantyCheckData('')
                        setWarrantyStatus('')
                        form.setFieldsValue({
                          warranty: undefined
                        })
                      }}
                      value={getFieldValue('productName')}
                      dropdownMatchSelectWidth={false}
                      onSelect={onProductSelect}
                      placeholder={'Product Name'}>
                      {productList &&
                        productList.map((val, item) => (
                          <Option value={val._id}>
                            <>
                              <div>
                                <b>{val.name}</b>
                              </div>
                            </>
                          </Option>
                        ))}
                    </AutoComplete>

                    <Input
                      placeholder={'Machine Id'}
                      value={machineId}
                      style={{ width: '30%' }}
                      onChange={val => {
                        setMachineId(val.target.value)
                      }}
                    />

                    <Button
                      style={{ marginLeft: '3px' }}
                      loading={searchLoader}
                      type="primary"
                      onClick={() => {
                        let machine = ''
                        if (!form.getFieldValue('productName')) {
                          return notification.error({
                            message: 'Select Product.'
                          })
                        }
                        if (!machineId) {
                          return notification.error({
                            message: 'Enter machine Id.'
                          })
                        }
                        machine = `${productInitial}-${machineId}`

                        getClaimHistory(machine)
                      }}>
                      {<SearchOutlined />}
                    </Button>
                  </Input.Group>
                </Form.Item>

                <GetAllFormFields
                  apiurl={API_URL}
                  inputSchema={inputType}
                  formItemLayout={formItemLayout}
                  getFieldDecorator={getFieldDecorator}
                />

                <Form.Item
                  label={'Type'}
                  name="type"
                  {...formItemLayout}
                  required={true}>
                  <Radio.Group
                    name="type"
                    onChange={val => {
                      if (val.target.value === 'Installation') {
                        form.setFieldsValue({
                          warranty: 'Brand New'
                        })
                      } else {
                        form.setFieldsValue({
                          warranty: undefined
                        })
                      }
                    }}>
                    <Radio value={'Complaint'}>Complaint</Radio>
                    <Radio value={'Installation'}>Installation</Radio>
                  </Radio.Group>
                </Form.Item>

                <GetAllFormFields
                  apiurl={API_URL}
                  inputSchema={inputType1}
                  formItemLayout={formItemLayout}
                  getFieldDecorator={getFieldDecorator}
                />
              </Col>

              <Col span={8}>
                {warrantyCheckData ? (
                  <Card bordered={true} style={{ margin: '10px' }}>
                    <Descriptions bordered={false} size={'middle'} column={1}>
                      <Descriptions.Item label={<b>Sale Date</b>}>
                        {warrantyCheckData && warrantyCheckData.saleDate
                          ? moment(warrantyCheckData.saleDate).format(
                              'DD/MM/YYYY'
                            )
                          : ''}
                      </Descriptions.Item>

                      <Descriptions.Item label={<b>Product Name</b>}>
                        {warrantyCheckData && warrantyCheckData.productName}
                      </Descriptions.Item>
                      <Descriptions.Item label={<b>Warranty Upto</b>}>
                        {warrantyCheckData && warrantyCheckData.warranty
                          ? moment(warrantyCheckData.warranty).format(
                              'DD/MM/YYYY'
                            )
                          : ''}
                      </Descriptions.Item>
                      <Descriptions.Item label={<b>Warranty Status</b>}>
                        {
                          <Tag color={getWarrantyStatusColor(warrantyStatus)}>
                            {warrantyStatus}
                          </Tag>
                        }
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                ) : (
                  ''
                )}
              </Col>
            </Row>

            <Form.Item style={{ marginTop: 32, textAlign: 'center' }}>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ width: '15%' }}>
                {id ? `UPDATE` : `SAVE`}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </PageHeaderWrapper>
    </>
  )
}
export default AddComplain
