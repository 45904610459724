import axios from 'axios'
import { getAllOrderDispatchUrl, updateOrderDispatchStatusUrl, updateOrderUrl,deletePhotosAndVideosApi } from '../api'
import { getToken } from '../../../request'
import { notification } from 'antd'

export const getAllOrderDispatchAction = async (params) => {
  try {
    let { data } = await axios.get(getAllOrderDispatchUrl(), { params: { ...params }, ...getToken() })
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const updateOrderDispatchStatusAction = async params => {
  try {
    let { data } = await axios.post(
      updateOrderDispatchStatusUrl(params._id),
      { ...params },
      getToken()
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const deletePhotosAndVideos = async params => {
  try {
    console.log("data",params)
    let { data } = await axios.post(
      deletePhotosAndVideosApi(params._id),
      { ...params },
      getToken()
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const updateOrderAction = async params => {
  try {
    let { data } = await axios.put(
      updateOrderUrl(params._id),
      { ...params },
      getToken()
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
