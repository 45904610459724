import React, { useState } from 'react'
import {
  AutoComplete, Button, Form, notification, Upload
} from 'antd'
import 'react-quill/dist/quill.snow.css'
import { putUploadFileAction } from '../actions'
import { API_URL } from '../../../request'
import { UploadOutlined } from '@ant-design/icons'

const uploadImage = (props) => {
  const [filesUpload, setFilesUpload] = useState([])
  const [loader, setLoader] = useState(false)
  const handleOkUpload = async () => {
    let { complaintId } = props
    if (!filesUpload.length) {
      notification.error({
        message: 'Select File To Upload'
      })
      return
    }
    let uploadObj = {
      images: filesUpload,
      _id: complaintId
    }
    setLoader(true)

    let { data } = await putUploadFileAction(uploadObj)
    setLoader(false)
    if (!data.error) {
      notification.success({
        message: 'successfully uploaded'
      })
      setFilesUpload([])
      if (props.handleImage) {
        props.handleImage()
      }

    } else {
      notification.error({
        message: 'failed to upload'
      })
    }

  }

  const handleFileChange = v => {
    if (v.fileList) {
      setFilesUpload(v.fileList)
    }
  }

  const handlePreview = async (file) => {
    let url
    if (file && file.response && file.response.path) {
      file.response.path = file.response.path.replace(/^public/, '')
      url = `${API_URL}/${file.response.path}`
    } else {
      url = `${file.url}`
    }

    window.open(url, '_blank')
  }

  return (
    <div>
      <Form>
        <Form.Item label={'Upload File'}>
          <Upload multiple={true}
                  name={'file'}
                  action={`${API_URL}/filesUploader`}
                  onChange={handleFileChange}
                  onRemove={() => {
                  }}
                  onPreview={handlePreview}
                  fileList={filesUpload}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>

        <div style={{ textAlign: 'right', marginTop: '20px', width: '90%' }}>

          <Button onClick={() => {
            props.onCancel()
            setFilesUpload([])
          }}>
            CANCEL
          </Button>
          <Button style={{ marginLeft: '10px' }} type="primary" onClick={() => {
            handleOkUpload()
          }} loading={loader}>
            SAVE
          </Button>

        </div>

      </Form>


    </div>
  )
}
export default uploadImage




