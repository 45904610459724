import React, { useEffect, useState } from 'react'
import styles from './login.less'
import {
  Form, Input, Button, Checkbox, notification
} from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import Request, { getToken } from '../../request'
import { showLoader, hideLoader } from '../../modules/actions'
import Emitter from '../../emitter'
import { getLoginUser } from '../users/actions'
import { useDispatch } from 'react-redux'

const NormalLoginForm = (props) => {
  const { loading, setLoading } = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const handleSubmit = async (values) => {

    let x = await Request.login(values)
    if (!x.error) {
      localStorage.setItem('token', x.token)
      localStorage.setItem('user', JSON.stringify(x.user))

      dispatch({
        type: 'SET_CURRENT_USER',
        user: x.user
      })

      // Reauth User
      Emitter.emit('reAuth')

      setTimeout(() => {
        window.location.href = '/'
      }, 1000)

    } else {
      notification.error({
        message: 'Invalid Login',
        description: x.message
      })

    }

  }
  const handleSubmitXX = () => {

  }

  return (
    <Form form={form}
          onFinish={handleSubmit}
          onFinishFailed={handleSubmitXX}
          className={styles.main}>
      <Form.Item name={'email'} rules={[{ required: true, message: 'Please input your username!' }]}>
        <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
      </Form.Item>
      <Form.Item name={'password'} rules={[{ required: true, message: 'Please input your Password!' }]}>
        <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password"
               placeholder="Password" />
      </Form.Item>
      <Form.Item>
        {/*<a className={styles.forgot} href="">Forgot password</a>*/}
        <Button type="primary" loading={loading} htmlType="submit" className={styles.button}>
          Log in
        </Button>
      </Form.Item>
    </Form>
  )

}

export default NormalLoginForm
