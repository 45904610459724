import React, { Component, useEffect, useState } from 'react'
import Request from '../../request'
import { Row, Col } from 'antd'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { getUrlPushWrapper } from '../../routes'
import { useDispatch } from 'react-redux'
import DashboardStats from '../../components/dahboardCounts/orderAndComplainStats'
const Dashboard = props => {
  return (
    <>
      <PageHeaderWrapper title="Dashboard">
        <DashboardStats/>
      </PageHeaderWrapper>
    </>
  )
}

export default Dashboard
