import React, { Component, Suspense } from 'react'
import { Route, Link, Redirect, Switch } from 'react-router-dom'
import menuData from '../../routes'
import Exception from '../../components/Exception'
import AuthLayout from '../../layouts/AuthLayout'
import BasicLayout from '../../layouts/BasicLayout'
import Login from '../../containers/login'
import IdleTimer from 'react-idle-timer'
import '../../index.css'
import _ from 'lodash'
import socketio from 'socket.io-client'
import { notification } from 'antd'
import Emitter from '../../emitter'
import { API_URL, pageTitle } from '../../request'

const Exp = () => (
  <Exception
    type="404"
    desc={'You Seems lost !!'}
    linkElement={Link}
    redirect={'/dashboard'}
    backText={'Go To Homepage?'}
  />
)
const Exp403 = () => (
  <Exception
    type="403"
    desc={"Sorry You Don't have access to this area !!"}
    linkElement={Link}
    redirect={'/dashboard'}
    backText={'Go To Homepage?'}
  />
)

class BasicLayoutWrapper extends Component {
  render() {
    const { menuData, component, path } = this.props

    let user =
      localStorage.getItem('user') != 'undefined'
        ? JSON.parse(localStorage.getItem('user'))
        : null
    if (!user) {
      return <Redirect to="/login" />
    }

    let menuItem = _(menuData)
      .thru(function(coll) {
        return _.union(coll, _.map(coll, 'children'))
      })
      .flatten()
      .find({ path: path })

    if (
      menuItem.authority !== undefined &&
      menuItem.authority.indexOf(user.userType) === -1
    ) {
      console.log('this user should not be here ', path)
      return <Exp403 />
    }

    if (!menuItem.title) {
      menuItem.title = 'Untitled'
    }
    // console.log(menuItem)

    return (
      <BasicLayout
        location={window.location}
        title={pageTitle}
        pageTitle={`${menuItem.title}`}
        menuData={menuData}>
        {!!component ? (
          <Suspense fallback={<div>Loading....</div>}>
            <this.props.component />
          </Suspense>
        ) : (
          <Exp />
        )}
      </BasicLayout>
    )
  }
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: localStorage.getItem('token'),
      user:
        localStorage.getItem('user') != 'undefined'
          ? JSON.parse(localStorage.getItem('user'))
          : null
    }
    this.idleTimer = null

    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)

    this.socket = socketio.connect(API_URL)
    this.socket.on('logout_user_force', () => {
      console.log(
        ' logout_user_force logout_user_force logout_user_force logout_user_force '
      )
      this.logout()
    })

    this.socket.on('connect', async () => {
      console.log('conneciton succssful')
      this.reAuthenticate(this.socket)
    })
  
    this.socket.on('notifyDispatch', obj => {
    
      notification.success({
        title: 'Please Check Order',
        message: `New Order Received`
      })
    })

    this.socket.on('reloadUsers', () => {
      Emitter.emit('reloadUsers')
    })
  }

  componentDidMount() {
    Emitter.on('reAuth', () => {
      this.reAuthenticate(this.socket)
    })
  }

  componentWillUnmount() {
    Emitter.off('reAuth', () => {})
  }

  reAuthenticate = async socket => {
    let userToken = localStorage.getItem('token')
    if (userToken) {
      console.log('reAuthenticate with token at ' + new Date())
      socket.emit('authentication', { userToken })
    }
  }

  logout = async () => {
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    if (user) {
      console.log(user)
      user.isLoggedIn = false
      user.logoutTime = moment()
      let x = await Request.updateUserDetails(user)
      await Request.addLoginHistory({
        logoutTime: user.logoutTime,
        type: 'Logout',
        email: user && user.email,
        name: user && user.name,
        userType: user && user.userType,
        mobile: x && x.data && x.data.mobile
      })

      localStorage.removeItem('token')
      localStorage.removeItem('user')
      window.location.reload()
    }
  }

  handleOnAction(event) {
    // console.log('user did something ')
    this.socket.emit('userIsActive')
  }

  handleOnActive(event) {
    this.socket.emit('userIsActive')
  }

  handleOnIdle(event) {
    this.socket.emit('userIsIdle')
  }

  render() {
    const { user } = this.state
    return (
      <div>
        {localStorage.getItem('token') && (
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref
            }}
            timeout={30 * 60 * 1000}
            eventsThrottle={30 * 1000}
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            onAction={this.handleOnAction}
            debounce={250}
          />
        )}
        <Switch>
          <Route
            exact
            path="/form/basic-formsdf"
            render={route => {
              return (
                <div>
                  {menuData.map((item, key) => {
                    if (item.children) {
                      return item.children.map((child, k) => {
                        return <div key={item.children}>{child.path}</div>
                      })
                    }
                  })}
                </div>
              )
            }}
          />

          {menuData.map((item, key) => {
            if (!item.children) {
              return (
                <Route
                  exact
                  path={item.path}
                  key={item.path}
                  render={route => {
                    return (
                      <BasicLayoutWrapper
                        component={item.component}
                        path={item.path}
                        user={user}
                        menuData={menuData}
                      />
                    )
                  }}
                />
              )
            }
          })}

          {menuData.map((item, key) => {
            if (item.children) {
              return item.children.map((child, k) => {
                return (
                  <Route
                    exact
                    path={child.path}
                    key={child.path}
                    render={route => {
                      return (
                        <BasicLayoutWrapper
                          component={child.component}
                          path={child.path}
                          user={user}
                          menuData={menuData}
                        />
                      )
                    }}
                  />
                )
              })
            }
          })}

          <Route
            exact
            path="/login"
            render={route => {
              return (
                <AuthLayout location={window.location} menuData={menuData}>
                  <Login />
                </AuthLayout>
              )
            }}
          />

          <Route
            exact
            path="/"
            render={route => {
              return <Redirect to="/dashboard" />
            }}
          />
        </Switch>
      </div>
    )
  }
}

export default App
