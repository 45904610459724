import React, {Fragment} from 'react'
import styles from './AuthLayout.less'
import logo from '../assets/logo.svg'
import {Link} from 'react-router-dom'
import GlobalFooter from '../components/GlobalFooter'
import {CopyrightOutlined} from '@ant-design/icons'
import {pageTitle} from '../request'

const links = [
    {
        key: 'help',
        title: 'HELP',
        href: '',
    },
]

const copyright = (
    <Fragment>
        Copyright <CopyrightOutlined/> {(new Date()).getFullYear()}
    </Fragment>
)
const UserLayout = (props) => {

    const {children} = props
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.top}>
                    <div className={styles.header}>
                        <Link to="/">
                            <img alt="logo" className={styles.logo} src={logo}/>
                            <div className={styles.title}>{pageTitle}</div>
                        </Link>
                    </div>
                    <div className={styles.desc}></div>
                </div>
                {children}
            </div>
        </div>
    )

}

export default UserLayout
