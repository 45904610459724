import React, { useState, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils4'
import {
  Button,
  Card,
  Modal,
  Tooltip,
  Tag,
  Drawer,
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { getAllComplaintsAction } from '../actions'
import {
  MessageOutlined,
  ProfileOutlined,
  FileImageOutlined,
  IdcardOutlined,
  DownloadOutlined
} from '@ant-design/icons'

import Request, { warrantyStatus, getComplaintColor } from '../../../request'
import UploadFile from './uploadFile'
import AddComment from './addComment'
import { CommentLogs, StatusLogs, FilesLogs } from './logs'

const FixedComplaints = () => {
  const tableRef = useRef()
  const [count, setCount] = useState(0)
  const [complaintId, setComplaintId] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [fileDownloadLoader, setFileDownloadLoader] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [drawerLogsVisible, setdrawerLogsVisible] = useState(false)
  const [allComments, setAllComments] = useState([])
  const [allStatusLogs, setAllStatusLogs] = useState([])
  const [isModelUploadFile, setIsModelUploadFile] = useState(false)
  const [drawerUploadView, setDrawerUploadView] = useState(false)
  const [imageArray, setImageArray] = useState([])

  const showStatusLogs = () => {
    setDrawerVisible(true)
  }
  const showModal = () => {
    setIsModalVisible(true)
  }

  const showDrawer = () => {
    setDrawerVisible(true)
  }

  const onClose = () => {
    setDrawerVisible(false)
    setdrawerLogsVisible(false)
    setDrawerUploadView(false)
  }


  const handleCancel = () => {
    setIsModalVisible(false)
    setIsModelUploadFile(false)
  }

  const downloadDispatch = async () => {
    setFileDownloadLoader(true)

    const params = {}
    params.status = 'Fixed By Technician'


    const { error } = await Request.downloadComplaint(params)
    setFileDownloadLoader(false)

  }
  
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.status = 'Fixed By Technician'
      if (!params.sortField) {
        params.sortField = 'isReopened'
        params.sortOrder = 'descend'
      }
      let data = await getAllComplaintsAction({
        ...params, regExFilters: ['complaintId','complaintRaisedByName', 'complaintDetails', 'productName']
      })
      setCount(data.total)
      resolve(data)
    })
  }

  const myColumns = [
    {
      title: 'Complaint ID',
      key: 'complaintId',
      width: 140,
      searchTextName: 'complaintId',
      render: (text,record)=>{
        return <div>{record.complaintId}</div>
      }
    },
    {
      title: 'Lead Name',
      dataIndex: 'leadName',
      key: 'leadName',
      width: 200,
      searchTextName: 'lead name',
      fixed: 'left'
    },
    {
      title: 'Contact Details',
      key: 'data',
      width: 200,
      render: (text, record) => {
        return (<>
          {record.email}
          <br />
          {record.mobile}
          <br />
          {record.state}
        </>)
      },
      searchTextName: 'Mobile Number'
    },
    {
      title: 'RO Name',
      width: 100,
      dataIndex: 'complaintRaisedByName',
      key: 'complaintRaisedByName',
      searchTextName: 'ro name'
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      width: 200,
      key: 'productName',
      searchTextName: 'product name'

    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 100,
      key: 'type'
    },
    {
      title: 'Complaint Details',
      width: 200,
      dataIndex: 'complaintDetails',
      key: 'complaintDetails',
      searchTextName: 'details'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      key: 'status',
      render: (val,record) => {
        if (record.isReopened) {
          return <Tag color={'#B20000'} >{val}</Tag>
          
       }
       else{
        return <Tag color={getComplaintColor(val)}>{val}</Tag>
       }
      }
    },
    {
      title: 'Warranty',
      key: 'warranty',
      width: 150,
      dataIndex: 'warranty',
      filters: _.map(warrantyStatus, (x) => {
        return { text: x, value: x }
      }),
      render: (val) => {
        return <Tag>{val}</Tag>
      }

    },
    {
      title: 'Latest Comment',
      key: 'comments',
      width: 200,
      render: (comments) => {
        return (<>
          {comments.length ? comments[0].message : ''}
          {<br />}
          <small>{comments.length ? moment(comments[0].time).format('lll') : ''}</small>
          {<br />}
          {comments.length ?
            <Button type="primary" style={{ padding: '0 3px', fontSize: '10px', height: 'fit-content' }}
                    onClick={() => {
                      setAllComments(comments)
                      showDrawer()
                    }
                    }>
              View Comments
            </Button> : ''}
        </>)
      }
    },
    {
      title: 'Created At',
      key: 'createdAt',
      type: 'date',
      width: 150,
      sorter: true,
      render: (createdAt) => {
        return (<>
          {moment(createdAt).format('lll')}
        </>)
      }
      // searchDateName: 'Mobile Number'
    },

    {
      key: 'actions',
      title: 'Actions',
      fixed: 'right',
      width: 100,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip title="Add Comment">

              <Button
                shape="circle"
                style={{ marginRight: 6, marginBottom: 6 }}
                size="small"
                onClick={() => {
                  setComplaintId(record._id)
                  showModal()
                }}
                icon={<MessageOutlined />}
              />
            </Tooltip>
            <Tooltip title="View Status">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                onClick={() => {
                  setAllStatusLogs(record.statusLogs)
                  setdrawerLogsVisible(true)
                  showStatusLogs()
                }}
                icon={<ProfileOutlined />}
              />
            </Tooltip>
            <Tooltip title="Upload File">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                onClick={() => {
                  setComplaintId(record._id)
                  setIsModelUploadFile(true)
                }}
                icon={<FileImageOutlined />}
              />
            </Tooltip>
            <Tooltip title="View Files">
              <Button
                shape="circle"
                style={{ marginRight: 6, marginTop: 6 }}
                size="small"
                onClick={() => {
                  setDrawerUploadView(true)
                  setImageArray(record.imageArray)

                }}
                icon={<IdcardOutlined />}
              />
            </Tooltip>
          </React.Fragment>
        )
      }
    }

  ]
  return (<>
    <PageHeaderWrapper title={`Fixed Complaints (${count || 0})`}>
      <Card bordered={true}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Tooltip title="Export Csv">
            <Button
              type="warning"
              style={{ marginTop: '6px' }}
              loading={fileDownloadLoader}
              onClick={() => {
                downloadDispatch()
              }}
            ><DownloadOutlined/>
              Export Csv
            </Button>{' '}
          </Tooltip>
        </div>
        <TableComp
          ref={tableRef}
          columns={myColumns}
          extraProps={{ scroll: { x: 1300 } }}
          apiRequest={apiRequest}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ['10', '25', '50', '100'],
            showSizeChanger: true
          }}
        />
        <Modal title="Comment" visible={isModalVisible} onCancel={handleCancel} footer={false}>

          <AddComment complaintId={complaintId} onCancel={handleCancel} handleComment={async () => {
            setIsModalVisible(false)
            tableRef.current.reload()

          }} />
        </Modal>

        <Drawer title="All Comments" width={800} placement="right" onClose={onClose} visible={drawerVisible}>

          <CommentLogs allComments={allComments} />

        </Drawer>

        <Drawer title="All Status Logs" width={800} placement="right" onClose={onClose} visible={drawerLogsVisible}>
          <StatusLogs allStatusLogs={allStatusLogs} />
        </Drawer>

        <Drawer title="File Logs" width={800} placement="right" onClose={onClose} visible={drawerUploadView}>
          <FilesLogs imageArray={imageArray} />
        </Drawer>

        <Modal footer={false} title="Upload File" visible={isModelUploadFile}
               onCancel={handleCancel}>
          <UploadFile onCancel={handleCancel} complaintId={complaintId} handleImage={async () => {
            setIsModelUploadFile(false)
            tableRef.current.reload()


          }} />


        </Modal>
      </Card>
    </PageHeaderWrapper>
  </>)
}

export default FixedComplaints
