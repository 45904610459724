import Dashboard from './containers/dashboard'

import { push } from 'connected-react-router'
import _ from 'lodash'
import { DashboardOutlined, UserOutlined } from '@ant-design/icons'
import AddUsers from './containers/users/views/add'
import { Path } from 'path-parser'
import OpenComplaints from './containers/complaint/view'
import AddInward from './containers/inward/add/inwardForm'
import AddOutward from './containers/inward/add/outwardForm'
import AllInwardList from './containers/inward/all'
import AllStockList from './containers/inward/all/stockAll'
import TechComplaints from './containers/complaint/view/fixedComplaint'
import ComplaintToHO from './containers/complaint/view/complaintToHO'
import TransferToASC from './containers/complaint/view/transferToASC'
import ResolvedComplaints from './containers/complaint/view/closeComplaint'
import OrderDetails from './containers/dispatch/view/orderDetails'
import List from './containers/dispatch/view/list'
import AddStockProduct from './containers/stock/product/product'
import ViewLedger from './containers/stock/product/viewLedger'
import AllStockProduct from './containers/stock/product/productList'
import AddCategory from './containers/stock/category'
import StockProductList from './containers/stock/ledger'
import AddComplain from './containers/complaint/view/addComplaint'

let menu = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <DashboardOutlined />,
    key: 'dashboard',
    homepage: true,
    component: Dashboard,
    authority: ['Technician', 'Dispatch']
  },

  // {
  //   path: '/users',
  //   dontShowOnMenu: true,
  //   icon: <UserOutlined />,
  //   name: 'Users',
  //   key: 'users',
  //   authority: ['Technician'],
  //   children: [
  //     {
  //       path: '/users/add',
  //       name: 'Add User',
  //       title: 'Add User',
  //       key: 'add',
  //       component: AddUsers
  //     }
  //   ]
  // },
  {
    path: '/complaints',
    name: 'Complaints',
    icon: <DashboardOutlined />,
    key: 'complaints',
    homepage: true,
    authority: ['Technician'],
    children: [
      {
        path: '/complaints/open',
        name: 'Open Complaint',
        key: 'open',
        title: 'Open Complaint',
        component: OpenComplaints
      },
      {
        path: '/complaints/add',
        name: 'Add Complaint',
        title: 'Add Complaint',
        component: AddComplain
      },
      {
        path: '/complaints/solvedbytech',
        name: 'Fixed Complaint',
        key: 'fixed',
        title: 'Fixed Complaint',
        component: TechComplaints
      },
      {
        path: '/complaints/complaintToHO',
        name: 'Complaint To Head Office',
        key: 'complaintToHO',
        title: 'Complaint To Head Office',
        component: ComplaintToHO
      },
      {
        path: '/complaints/transferASC',
        name: 'Transfer To ASC',
        key: 'transferToASC',
        title: 'Transfer To ASC',
        component: TransferToASC
      },
      {
        path: '/complaints/resolved',
        name: 'Closed Complaint',
        key: 'close',
        title: 'Closed Complaint',
        component: ResolvedComplaints
      }
    ]
  },
  {
    path: '/inward',
    name: 'Inward-Outward',
    icon: 'stock',
    key: 'inward',
    authority: ['Dispatch'],
    children: [
      {
        path: '/inward/add',
        name: 'Add Inward',
        key: 'add',
        title: 'Add Inward',
        component: AddInward,
        dontShowOnMenu: true,
        authority: ['Dispatch'],
        dontShowOnMenu: true
      },
      {
        path: '/inward/edit',
        key: 'edit',
        title: 'Edit Inward',
        component: AddInward,
        authority: ['Dispatch'],
        dontShowOnMenu: true
      },
      {
        path: '/outward/add',
        name: 'Add Outward',
        key: 'outward',
        title: 'Add outward',
        component: AddOutward,
        dontShowOnMenu: true,
        authority: ['Dispatch']
        // dontShowOnMenu: true
      },
      {
        path: '/outward/edit',
        key: 'outwardEdit',
        title: 'Edit Inward',
        component: AddOutward,
        authority: ['Dispatch'],
        dontShowOnMenu: true
      },
      {
        path: '/inward/inwardOutwardList',
        name: 'List',
        key: 'inwardOutwardList',
        title: 'Inward/Outward List',
        component: AllInwardList,
        authority: ['Dispatch']
      },
      {
        path: '/inward/stock',
        name: 'Stock',
        key: 'stock',
        title: 'Stock',
        component: AllStockList,
        authority: ['Dispatch']
      }
    ]
  },
  {
    path: '/orderDispatch/orderDetails',
    name: 'Order Details',
    title: 'Order Details',
    key: 'orderDetails',
    dontShowOnMenu: true,
    component: OrderDetails,
    authority: ['Dispatch']
  },
  {
    path: '/stock',
    name: 'Stock',
    icon: 'stock',
    key: 'stock',
    title: 'Stock',
    authority: ['Dispatch'],
    children: [
      {
        path: '/stock/category/add',
        name: 'Add Category',
        title: 'Add Category',
        component: AddCategory
      },
      {
        path: '/stock/product/add',
        name: 'Add Product',
        title: 'Add Product',
        component: AddStockProduct
      },
      {
        path: '/stock/product/edit',
        name: 'Add Product',
        title: 'Add Product',
        key: 'stockProductEdit',
        component: AddStockProduct,
        dontShowOnMenu: true
      },
      {
        path: '/stock/product/all',
        name: 'Product List',
        title: 'Product List',
        component: AllStockProduct
      },
      {
        path: '/stock/view/ledger',
        name: 'Ledger',
        title: 'Ledger',
        dontShowOnMenu: true,
        component: ViewLedger
      }
    ]
  },
  {
    path: '/orderDispatch/dispatchedOrder',
    name: 'Dispatched Order',
    key: 'orderDispatch',
    title: 'Dispatched Order',
    component: List,
    authority: ['Dispatch']
  }
]

export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {
  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&')

  return `${val.path}?${queryString}`
}

export const getUrlParams = (keyString, route) => {
  let obj = getUrlObject(keyString)

  if (obj) {
    console.log('objjjjj is', obj, route)
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = keyString => {
  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}

export default menu
