import { API_URL } from '../../../request'

export const getAllComplaintsUrl = () => {
  return API_URL + `/complaint`
}
export const postCommentUrl = () => {
    return API_URL + "/add/complaint/comments";
}
export const postStatusUrl = () => {
  return API_URL + "/change/complaint/status";
}
export const putUploadFileUrl = (id) => {
  return API_URL + "/complaint/" + id;
}
