import { API_URL } from '../../../request'

export const getAllOrderDispatchUrl = () => {
  return API_URL + `/order/dispatch`
}
export const updateOrderDispatchStatusUrl = (id) => {
  return API_URL + `/add/orderStatusLogs/${id}`
}
export const deletePhotosAndVideosApi = (id) => {
  return API_URL + `/deletePhotosAndVideos/${id}`
}
export const updateOrderUrl = (id) => {
  return API_URL + `/order/dispatch/${id}`
}
