import React, { useState } from 'react'
import {
  AutoComplete, Button, Form, Modal, notification, Upload
} from 'antd'
import 'react-quill/dist/quill.snow.css'
import { postCommentAction, putUploadFileAction } from '../actions'
import { API_URL } from '../../../request'
import { UploadOutlined } from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea'

const addComment = (props) => {
  const [textArea, setTextArea] = useState('')
  const [loader, setLoader] = useState(false)

  const handleOk = async () => {
    let { complaintId, handleComment } = props

    if (textArea === '') {
      notification.error({
        message: 'Enter Comment'
      })
      return
    }
    let commentObj = {
      complaintId: complaintId,
      comment: textArea
    }
    setLoader(true)
    let { data } = await postCommentAction(commentObj)
    setLoader(false)
    if (!data.error) {

      notification.success({
        message: data.message
      })
      setTextArea('')
      if (handleComment) {
        handleComment()
      }
    } else {
      notification.error({
        message: data.message
      })
    }
  }


  const handleChange = (val) => {
    setTextArea(val.target.value)
  }


  return (
    <div>
      <Form>
        <Form.Item label={'Comment'}>
          <TextArea value={textArea} onChange={handleChange} rows={4} placeholder="Type Your Comment" />

        </Form.Item>

        <div style={{ textAlign: 'right', marginTop: '20px', width: '90%' }}>

          <Button onClick={() => {
            props.onCancel()
            setTextArea('')
          }}>
            CANCEL
          </Button>
          <Button style={{ marginLeft: '10px' }} type="primary" onClick={() => {
            handleOk()
          }} loading={loader}>
            SAVE
          </Button>

        </div>

      </Form>


    </div>
  )
}
export default addComment




