import React, { useState } from 'react'

import {
  Form,
  Button,
  Card,
  Input,
  Tooltip,
  Popconfirm,
  Tag,
  Drawer,notification
} from 'antd'
import Request from '../../../request'
import { useForm } from 'antd/lib/form/Form'
const FormComp = props => {
  const [form] = Form.useForm()
  let { getFieldDecorator } = form
  const [id,setId] = useState(null)
  const [loading,setLoading] = useState(false)
 let handleSubmit = async (valData) => {
        
                let values = _.clone(valData)
 

               setLoading(true)

                let x = null
                if (id !== null) {
                    values._id = id

                    x = await Request.updateIP(values)
                } else {
                    x = await Request.addCategory(values)
                }


                if (!x.error) {

                    notification.success({
                        message: id
                            ? x.message
                            : x.message
                    })

                    if (id === null) {
                        form.resetFields()
                        props.tableReload()
                  

                    }


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }
                setLoading(false)
    }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 12 }
    }
  }
  const submitFormLayout = {
    wrapperCol: {
        xs: {span: 24, offset: 0},
        sm: {span: 10, offset: 7},
        md: {span: 12, offset: 8}
    }
}
  return (
    <>
      <Card bordered={true}>
        <Form
          // onSubmit={this.handleSubmit}
          onFinish={val => handleSubmit(val)}
          hideRequiredMark
          form={form}>
          <div>
            <Form.Item
              label={'Category'}
              name="category"
              {...formItemLayout}
              rules={[
                {
                  required: true,
                  message: 'Please Add Category!'
                }
              ]}
              required={true}>
              <Input />
            </Form.Item>

            <Form.Item {...submitFormLayout}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                >
                ADD
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Card>
    </>
  )
}

export default FormComp
